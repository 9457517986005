import axios from "axios";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { chargingAction, chargingActions } from "../actions/charging";
import { push } from "connected-react-router";
import moment from "moment";
import { storageKey, saveState, clearState } from "../localStorage";

const delay = time => new Promise(resolve => setTimeout(resolve, time));
const getChargingInProgress = (state) => state.charging.chargingInProgress;
const getStatusQueryInProgress = (state) => state.charging.statusQueryInProgress;

function* initializeCharge(action) {
    try {
        const startTime = moment().format();
        const body = {
            transactionId: action.payment.transactionId,
            fingerprint: action.payment.fingerprint,
            timeZone: moment.parseZone(startTime).utcOffset()
        };
        saveState(storageKey.INITIALIZE_TIMEOUT_STARTED, startTime);

        const response = yield call(axios.post, "/Charging/Start", body);
        if (response.data.success == true){
            if (response.data.info != null && response.data.info == "Elinta") {
                yield call(axios.post, "/Charging/ElintaSQSMessage", {delay: 20000});
            }
            if (yield select(getStatusQueryInProgress) == true)
            {
                yield put(chargingAction.getStatus(action.payment));
            }
        }
        else if (response.data.failureCode == "5801"){ // 5801 = Elinta session was not started
            // Try 2 more times until the timer runs out. Each call takes 15 seconds
            var stopCharging = true;
            for (var i = 1; i < 3; i++){
                const newResponse = yield call(axios.post, "/Charging/Start", body);
                if (newResponse.data.success == true) {
                    stopCharging = false;
                    yield call(axios.post, "/Charging/ElintaSQSMessage", {delay: 20000});
                    if (yield select(getStatusQueryInProgress) == true) {
                        yield put(chargingAction.getStatus(action.payment));
                    }
                    break;
                }
                else if (newResponse.data.failureCode != "5801"){ // not elinta session start error, then don't try starting anymore
                    i = 3;
                }
            }
            if (stopCharging == true){
                yield put(chargingAction.stopCharging(action.payment));
            }
        }
        else {
            yield put(chargingAction.stopCharging(action.payment));
            //this.stopCharging(action);
        }
    }
    catch (e) {
        // eslint-disable-next-line
        console.log(e);
        // eslint-disable-next-line
        console.log(e.response);
    }
}

function* getStatus(action) {
    try {
        const body = {
            transactionId: action.payment.transactionId,
            fingerprint: action.payment.fingerprint,
        };

        const response = yield call(axios.post, "/Charging/Status", body);

        const statusQueryInProgress = yield select(getStatusQueryInProgress);
        if (!statusQueryInProgress) {
            yield put(chargingAction.setStatusQueryInProgress(true));
        }

        if (response.data.billingStartTime) {
            yield put(chargingAction.getStatusSuccess(response.data));
        }
        else {
            yield put(chargingAction.waiting());
        }

        yield call(delay, 2000);
        const chargingInProgress = yield select(getChargingInProgress);

        if (chargingInProgress && response.data.isCharging && response.data.success) {
            yield put(chargingAction.getStatus(action.payment));
        }
        else if (!(response.data.isCharging && response.data.success)) {
            yield put(chargingAction.resetCharging(action.payment));
            yield put(chargingAction.getStatusSuccess(response.data));
            yield put(push("/summary"));
        }
    } catch (e) {
        // We want to keep going on timedout events
        if (e.response.status === 504) {
            yield put(chargingAction.getStatus(action.payment));
        }
        else {
            // eslint-disable-next-line
            console.log(e);
            // eslint-disable-next-line
            console.log(e.response);
        }
    }
}

function* stopCharging(action) {
    try {
        const body = {
            transactionId: action.payment.transactionId,
            fingerprint: action.payment.fingerprint,
        };

        const response = yield call(axios.post, "/Charging/Stop", body);

        yield put(chargingAction.getStatusSuccess(response.data));

        yield put(push("/summary"));
    }
    catch (e) {
        // eslint-disable-next-line
        console.log(e);
        // eslint-disable-next-line
        console.log(e.response);
    }
}

function* chargingSaga() {
    yield takeEvery(chargingActions.INITIALIZE_CHARGE, initializeCharge);
    yield takeEvery(chargingActions.GET_STATUS, getStatus);
    yield takeEvery(chargingActions.STOP_CHARGING, stopCharging);
}

export default chargingSaga;
