import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { ReactComponent as CCSIcon } from "../../images/CCS.svg";
import { ReactComponent as CHAdeMOIcon } from "../../images/CHAdeMO.svg";
import { ReactComponent as Type2Icon } from "../../images/type_2.svg";
import { invert } from "lodash";

const styles = theme => ({
  logo: {
    backgroundColor: "inherit",
    boxShadow: "none"
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none",
    padding: theme.spacing(3),
    //height: "100px",
    //width: "100px",
    color: "white",
    textAlign: "center"
  },
  labelText: {
    color: theme.text.primary
  },
  available: {
    backgroundColor: theme.palette.secondary.main
  },
  unAvailable: {
    backgroundColor: theme.palette.unActiveBackgroud.main
  },
  inUse: {
    backgroundColor: theme.palette.inUseBackgroud.main //"#ffb808"
  },
  headerText: {
    color: theme.text.primary,
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: 'Montserrat'
  },
  extraText: {
    color: theme.text.primary,
    fontWeight: 700,
    fontSize: "12px",
    fontFamily: 'Montserrat'
  },
  activeImage:{
    color: theme.palette.icons.activeAlternative,
    fill: "none",
    stroke: "none",
    width: "47.5px",
    height: "47.5px",
  },
  inactiveImage:{
    color: theme.palette.icons.inactiveAlternative,
    fill: theme.palette.icons.inactiveAlternative,
    stroke: "none",
    width: "47.5px",
    height: "47.5px",
    filter: "invert(100%)"
  },

});

const ConnectorIcon = ({connector, availablelabel, classes}) => {
  let image = null;
  let available = false;
  let chargingUnitGrid = null;
  if (availablelabel == "AVAILABLE") available = true;
  switch (connector.type){
    case "CHAdeMO":
      if (available) image = (<CHAdeMOIcon className={classes.activeImage} />);
      else image = (<CHAdeMOIcon className={classes.inactiveImage} />);
    break;
    case "CCS":
      if (available) image = (<CCSIcon className={classes.activeImage} />);
      else image = (<CCSIcon className={classes.inactiveImage} />);
    break;
    case "Type 2":
    default:
      if (available) image = (<Type2Icon className={classes.activeImage} />);
      else image = (<Type2Icon className={classes.inactiveImage} />);
    break;
  }

  chargingUnitGrid = available == true ? (
    <Grid container spacing={0}>
      <Grid item xs={12} >
        {image}
      </Grid>
    </Grid> ) : (
    <Grid container spacing={0}>
      <Grid item xs={12} >
        {image}
      </Grid>
  </Grid> );

return chargingUnitGrid;
}

const ChargingUnitButton = ({ content, type, classes }) => {

  let availableLabel = "AVAILABLE";
  let extraInfo = "";

  var availabilityClass = classes.available;
  if (content.chargerId.includes('Elinta')) {
    if (content.available && content.status == 3 ){
      availableLabel = "AVAILABLE";
      availabilityClass = classes.available;
    }
    else if (content.available && (content.status == 4 || content.status == 5)){
      availableLabel = "AVAILABLE";
      availabilityClass = classes.available;
      extraInfo = "Cable Connected";
    }
    else if (content.available && content.status == 7){
      availableLabel = "AVAILABLE";
      availabilityClass = classes.available;
    }
    else if (content.status == 6) {
      availableLabel = "IN USE";
      availabilityClass = classes.inUse;
    }
    else if (!content.available && (content.status == 6 || content.status == 7)) {
      availableLabel = "IN USE";
      availabilityClass = classes.inUse;
    }
    else if (!content.available || (content.status < 3 || content.status > 7)){
      availableLabel = "UNAVAILABLE";
      availabilityClass = classes.unAvailable;
    }
  }
  else {
    availableLabel = content.available ? "AVAILABLE" : "IN USE";
    availabilityClass = content.available ? classes.available : classes.inUse;
  }

  return (
    <Grid container align="center">
      <Grid item xs={12}  >
      <Typography variant="subtitle2" className={classes.headerText}>
            {content.type}
          </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          className={classes.paper + " " + availabilityClass}
          elevation={1}
        >
          < ConnectorIcon connector={content} availablelabel={availableLabel} classes={classes}/>
        </Paper>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.headerText}>
           {availableLabel}
          </Typography>
          <Typography variant="subtitle1" className={classes.extraText}>
           {extraInfo}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ChargingUnitButton);
