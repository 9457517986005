import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CheckoutForm from "../payment/CheckoutForm";
import { requestPreAuthorize, requestCompletePreAuthorize, initStates } from "../../actions/payment";
import { chargingAction } from "../../actions/charging";
import Spinner from "../shared/Spinner";
import PageHeaderComponent from "../shared/PageHeaderComponent";
import { isEmpty } from "lodash";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { storageKey } from "../../localStorage";
import PrivacyPolicyView from "./PrivacyPolicyView";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1
  },
  formGrid: {
    maxWidth: "380px",
    minWidth: "260px"
  }

});

class PaymentView extends Component {
  componentDidMount() {
    const payment = localStorage.getItem(storageKey.PAYMENT);
    const serializedPayment = JSON.parse(payment);
    const chargingInProgress = localStorage.getItem(storageKey.CHARGING_IN_PROGRESS);

    if (serializedPayment && chargingInProgress === "true") {
      push("/charging");
    }

    this.props.initStates();
  }

  render() {
    const {
      amount,
      prePayment,
      completePayment,
      isLoading,
      classes,
      requestPreAuthorize,
      requestCompletePreAuthorize,
      isPreCompleted,
      isCompleteCompleted,
      push,
      initializeCharge,
      isPreErrored,
      isCompleteErrored
    } = this.props;

    if (isEmpty(prePayment) && !isPreErrored && !isLoading) {
      requestPreAuthorize();
    }

    if (!isEmpty(completePayment) && isCompleteCompleted && !isLoading) {
      initializeCharge(completePayment);
      push("/charging");
    }

    return (
      <div className={classes.root}>
        {isLoading || prePayment == null ? (
          <Grid container>
            <Grid item xs={10} align="center">
              <Spinner />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} justify="center" align="center" direction="row">

            <Grid item xs={12} md={6}>

              <Grid container spacing={2} justify="center" align="center" direction="column">
                <Grid item>
                  <PageHeaderComponent label="Payment" headerVariant="h5" />
                </Grid>
                <Grid item >
                  <div className={classes.formGrid}>
                  <Elements options={{ locale: "en" }} stripe={stripePromise}>
                    <CheckoutForm
                      requestCompletePreAuthorize={requestCompletePreAuthorize}
                      amount={amount}
                      clientSecretValue={prePayment.client_secret}
                      label="Credit card New payment!"
                    />
                  </Elements>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2} justify="center" align="center" direction="column">
                <Grid item>
                  <PrivacyPolicyView scrollable={true} />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  amount: state.payment.amount,
  prePayment: state.payment.prePaymentInfo,
  completePayment: state.payment.completePaymentInfo,
  isPreCompleted: state.payment.isPreCompleted,
  isCompleteCompleted: state.payment.isCompleteCompleted,
  isPreErrored: state.payment.isPreErrored,
  isCompleteErrored: state.payment.isCompleteErrored,
  isLoading: state.payment.isLoading,
});

const mapDispatchToProps = dispatch => ({
  requestPreAuthorize: () => dispatch(requestPreAuthorize()),
  requestCompletePreAuthorize: (email) => dispatch(requestCompletePreAuthorize(email)),
  push: directory => dispatch(push(directory)),
  initializeCharge: payment => dispatch(chargingAction.initializeCharge(payment)),
  initStates: () => dispatch(initStates())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentView));
