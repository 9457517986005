import { SELECT_AREA, SELECT_STATION } from "../actions/stations";
import { SELECT_CHARGER } from "../actions/charger";

const initialState = {
	selectedArea: undefined,
	selectedStation: undefined,
	selectedChargingUnit: undefined
};

export default (state = initialState, action) => {
	switch (action.type) {
	case SELECT_AREA:
		return {
			...state,
			selectedArea: action.stationArea
		};
	case SELECT_STATION:
		return {
			...state,
			selectedStation: action.selectedStation
		};
	case SELECT_CHARGER:
		return {
			...state,
			selectedChargingUnit: action.chargerUnit
		};
	default:
		return state;
	}
};
