import {
	STORE_AMOUNT,
	STORE_PRE_PAYMENT_INFO,
	STORE_PRE_PAYMENT_COMPLETED,
  STORE_COMPLETE_PAYMENT_INFO,
	STORE_COMPLETE_PAYMENT_COMPLETED,
  STORE_PRE_PAYMENT_ERRORED,
  STORE_COMPLETE_PAYMENT_ERRORED,
	IS_LOADING
} from "../actions/payment";

import { chargingActions } from "../actions/charging";

const initialState = {
	amount: 0,
  prePaymentInfo: {},
	completePaymentInfo: {},
	isLoading: false,
	isPreCompleted: false,
  isCompleteCompleted: false,
  isPreErrored: false,
  isCompleteErrored: false
};

const stopCharging = (state) => {
	return {
		...state,
		amount: 0,
		completePaymentInfo: {}
	};
};

export default (state = initialState, action) => {
	switch (action.type) {
	case STORE_AMOUNT:
		return {
			...state,
			amount: action.amount
		};
	case STORE_PRE_PAYMENT_INFO:
		return {
			...state,
			prePaymentInfo: action.response
		};
  case STORE_PRE_PAYMENT_COMPLETED:
    return {
      ...state,
      isPreCompleted: action.isCompleted
    };
  case STORE_COMPLETE_PAYMENT_INFO:
    return {
      ...state,
      completePaymentInfo: action.response
    };
  case STORE_COMPLETE_PAYMENT_COMPLETED:
    return {
      ...state,
      isCompleteCompleted: action.isCompleted
    };
  case STORE_PRE_PAYMENT_ERRORED:
    return {
      ...state,
      isPreErrored: action.isErrored
    };
  case STORE_COMPLETE_PAYMENT_ERRORED:
    return {
      ...state,
      isCompleteErrored: action.isErrored
    };
	case IS_LOADING:
		return {
			...state,
			isLoading: action.isLoading
		};
	case chargingActions.RESET_CHARGING:
		return stopCharging(state);
	case chargingActions.STOP_CHARGING:
		return stopCharging(state);
	default:
		return state;
	}
};
