import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ContentEN from "./PrivacyPolicyContentEN";
import ContentFI from "./PrivacyPolicyContentFI";

const styles = theme => ({
  title: {
    color: theme.text.primary,
    textAlign: "left"
  },
  content: {
    color: theme.text.secondary,
    textAlign: "left",
    "& > h6": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    }
  }
});

const PrivacyPolicyContent = ({ classes, language }) => {

  const getPolicyContent = (language) => {
    switch (language) {
      case "EN":
        return <ContentEN />;
      case "FI":
        return <ContentFI />
      default:
        return <ContentEN />
    }
  };

  return (
    <div className={classes.content}>
      { getPolicyContent(language) }
    </div>
  );
};

export default withStyles(styles)(PrivacyPolicyContent);
