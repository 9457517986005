import axios from "axios";
import { storageKey } from "../localStorage";

export const STORE_AMOUNT = "STORE_AMOUNT";
export const STORE_PRE_PAYMENT_INFO = "STORE_PRE_PAYMENT_INFO";
export const STORE_PRE_PAYMENT_COMPLETED = "STORE_PRE_PAYMENT_COMPLETED";
export const STORE_PRE_PAYMENT_ERRORED = "STORE_PRE_PAYMENT_ERRORED";

export const STORE_COMPLETE_PAYMENT_INFO = "STORE_COMPLETE_PAYMENT_INFO";
export const STORE_COMPLETE_PAYMENT_COMPLETED = "STORE_COMPLETE_PAYMENT_COMPLETED";
export const STORE_COMPLETE_PAYMENT_ERRORED = "STORE_COMPLETE_PAYMENT_ERRORED";

export const IS_LOADING = "IS_LOADING";

export const storeAmount = amount => dispatch => {
  localStorage.removeItem(storageKey.PAYMENT); // clear old payment data when changing the amount
  const amountInCents = amount * 100;
  dispatch({ type: STORE_AMOUNT, amount: amountInCents });
};

export const storePrePaymentInfo = response => dispatch => {
  dispatch({ type: STORE_PRE_PAYMENT_INFO, response });
};

export const isPreRequestCompleted = isCompleted => dispatch => {
  dispatch({ type: STORE_PRE_PAYMENT_COMPLETED, isCompleted });
};

export const isPreRequestErrored = isErrored => dispatch => {
  dispatch({ type: STORE_PRE_PAYMENT_ERRORED, isErrored});
};

export const storeCompletePaymentInfo = response => dispatch => {
  dispatch({ type: STORE_COMPLETE_PAYMENT_INFO, response });
};

export const isCompleteRequestCompleted = isCompleted => dispatch => {
  dispatch({ type: STORE_COMPLETE_PAYMENT_COMPLETED, isCompleted });
};

export const isCompleteRequestErrored = isErrored => dispatch => {
  dispatch({ type: STORE_COMPLETE_PAYMENT_ERRORED, isErrored});
};

export const isLoading = isLoading => dispatch => {
  dispatch({ type: IS_LOADING, isLoading });
};

export function initStates() {
  return (dispatch) => {
    dispatch(isLoading(false));
    dispatch(isPreRequestCompleted(false));
    dispatch(isPreRequestErrored(false));
    dispatch(isCompleteRequestCompleted(false));
    dispatch(isCompleteRequestErrored(false));
    dispatch(storePrePaymentInfo(null));
    dispatch(storeCompletePaymentInfo(null));
    return;
  };
}

export function requestPreAuthorize() {
  return (dispatch, getState) => {
    const { amount } = getState().payment;
    const { selectedStation } = getState().selections;
    const { selectedChargingUnit } = getState().selections;
    const { selectedArea } = getState().selections;
    dispatch(isLoading(true));
    dispatch(isPreRequestCompleted(false));
    dispatch(isPreRequestErrored(false));
    dispatch(storePrePaymentInfo(null));
    dispatch(storeCompletePaymentInfo(null));
    dispatch(isCompleteRequestCompleted(false));
    dispatch(isCompleteRequestErrored(false));

    if (
      selectedStation &&
      selectedStation.stationId &&
      selectedChargingUnit &&
      selectedChargingUnit.chargingUnitId &&
      selectedStation.name &&
      selectedChargingUnit.standard &&
      selectedArea.name
    ) {
      const data = {
        stationId: selectedStation.stationId,
        stationName: selectedStation.name,
        stationAreaName: selectedArea.name,
        chargingUnitId: selectedChargingUnit.chargingUnitId,
        chargingUnitStandard: selectedChargingUnit.standard,
        amount: amount,
        description: "From mobile app",
        currency: "eur"
      };
      
      axios.post("/charging/payment", data).then(res => {
        dispatch(storePrePaymentInfo(res.data));
        dispatch(isPreRequestCompleted(true));
      }).catch(error => {
        dispatch(isPreRequestErrored(true));
      }).then(() => {
        dispatch(isLoading(false));
      });
    }
    return;
  };
}

export function requestCompletePreAuthorize(email) {
  return (dispatch, getState) => {
    const { prePaymentInfo } = getState().payment;
    dispatch(isLoading(true));
    dispatch(isCompleteRequestCompleted(false));
    dispatch(isCompleteRequestErrored(false));

    const data = {
      transactionId: prePaymentInfo.transactionId,
      customerEmail: email
    };

    axios.post("/charging/completepayment", data).then(res => {
      dispatch(storeCompletePaymentInfo(res.data));
      dispatch(isCompleteRequestCompleted(true));
    }).catch(error => {
      dispatch(isCompleteRequestErrored(true));
    }).then(() => {
      dispatch(isLoading(false));
    });

    return;
  };
}
