import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StationsListItem from "../stations/StationsListItem";
import StationAreaInfo from "./StationAreaInfo";

const styles = theme => ({
  root: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.light
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    padding: "8px"
  },
  primaryText: {
    color: theme.text.primary
  },
  priceArea: {
    color: theme.text.white
  },
  accentText: {
    color: theme.text.accent
  },
  paddingBottom25: {
    paddingBottom: "25px"
  },
  paddingBottom15: {
    paddingBottom: "15px"
  },
  rightMargin: {
    paddingRight: "10px",
    paddingTop: "20px"
  },
  leftMargin: {
    paddingLeft: "10px",
    paddingTop: "20px"
  }
});

class StationsAreaListItem extends React.Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  connectorConnected = (connector) => {
    if (connector.chargingUnitId.includes('Elinta')){
      if (connector.elintaConnectorStatus >= 3 && connector.elintaConnectorStatus <= 5 ) return true;
      else return false;
    }
    else return true;
  }

  getAvailableUnitsCountPerStandard(list, standard) {
    const CCSList = list.filter(item => item.standard === standard);
    return CCSList.filter(c => c.available && this.connectorConnected(c)).length;
  }

  StationsList = ({stations}) => {
    const { storeSelectedStation, classes, redirect } = this.props;
    stations = stations.sort((a,b) => (a.stationNumber > b.stationNumber) ? 1 : -1)
    var stationsList = stations.map(function(stationItem, index){
      var isEven = false;
      if (index % 2 == 0) isEven = true;
      return  isEven ? (
                <Grid item xs={6} key={stationItem.stationId} className={classes.rightMargin}>
                  <StationsListItem
                    station={stationItem}
                    clickHandler={station => {
                      storeSelectedStation(station);
                      redirect(station.stationId);
                    }}
                    />
                </Grid> ) : (
                <Grid item xs={6} key={stationItem.stationId}  className={classes.leftMargin}>
                  <StationsListItem
                    station={stationItem}
                    clickHandler={station => {
                      storeSelectedStation(station);
                      redirect(station.stationId);
                    }}
                    />
                </Grid>
                );
    })
    return(
      <Grid container spacing={0}>
        {stationsList}
      </Grid>
    );
  }

  render() {
    const { stationArea, storeSelectedStation, classes, redirect } = this.props;
    const stations = stationArea.stations.map(s => s);
    const units = stations.map(s => s.chargingUnits);
    const allUnitsCount = [].concat(...units);
    const availableCCSCount = this.getAvailableUnitsCountPerStandard(
      allUnitsCount,
      "CCS"
    );
    const availableCHAdeMOCount = this.getAvailableUnitsCountPerStandard(
      allUnitsCount,
      "CHAdeMO"
    );
    const availableType2Count = this.getAvailableUnitsCountPerStandard(
      allUnitsCount,
      "Type 2"
    );
    const availableUnitsCount = [availableCCSCount, availableCHAdeMOCount, availableType2Count];
    const stationsList = this.StationsList({stations});
    return (
      <ListItem>
        <Grid container spacing={0} className={classes.paddingBottom15}>
          <Grid item xs={12} className={classes.paddingBottom15}>
              <StationAreaInfo stationArea={stationArea} />
          </Grid>
          <Grid item xs={12}>
            {stationsList}
            </Grid>
        </Grid>
      </ListItem>
    );
  }
}

StationsAreaListItem.propTypes = {
  stationArea: PropTypes.object
};

StationsAreaListItem.defaultPropTypes = {
  stationArea: []
};

export default withStyles(styles)(StationsAreaListItem);
