import {
	IS_LOADING,
	STORE_STATION_AREAS,
	STORE_STATIONS
} from "../actions/stations";

const initialState = {
	isLoading: false,
	stationAreas: [],
	stations: []
};

export default (state = initialState, action) => {
	switch (action.type) {
	case IS_LOADING:
		return {
			...state,
			isLoading: action.isLoading
		};
	case STORE_STATION_AREAS:
		return {
			...state,
			stationAreas: action.stationAreas
		};
	case STORE_STATIONS:
		return {
			...state,
			stations: action.stations
		};
	default:
		return state;
	}
};
