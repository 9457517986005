import moment from "moment";
import { storageKey, saveState, clearState } from "../localStorage";
import { chargingActions, chargingStatusText } from "../actions/charging";

const initialState = {
  chargingInProgress: false,
  status: {},
  statusText: chargingStatusText.PREPARING,
  startTime: undefined,
  elapsedTime: undefined,
  endTime: undefined,
  timestamp: undefined,
  statusQueryInProgress: false
};

const stopCharging = (state) => {
  return {
    ...state,
    statusText: chargingStatusText.ENDING,
    chargingInProgress: false,
    statusQueryInProgress: false
  };
};

const statusSuccess = (state, action) => {
  const text = state.chargingInProgress ? chargingStatusText.CHARGING : chargingStatusText.ENDING;
  const endTimeTemp = action.status.billingEndTime == null ? moment() : moment(action.status.billingEndTime);
  const startTimeTemp = moment(action.status.billingStartTime);
  const timestampTemp = moment(action.status.timestamp);
  const elapsedTimeTemp = timestampTemp.diff(startTimeTemp, "minutes"); // calculate elapsed time from the back ends timestamp PUCEMPD-476
  return {
    ...state,
    statusText: text,
    startTime: startTimeTemp, 
    timestamp: timestampTemp,
    endTime: endTimeTemp, 
    elapsedTime: elapsedTimeTemp, 
    status: action.status
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case chargingActions.CLEAR_CHARGING_STATUS:
      clearState();
      return {
        ...state,
        startTime: undefined,
        elapsedTime: 0,
        endTime: undefined,
        timestamp: undefined,
        status: {}
      };
    case chargingActions.GET_STATUS:
      return {
        ...state
      };
    case chargingActions.GET_STATUS_SUCCESS:
      return statusSuccess(state, action);
    case chargingActions.INITIALIZE_CHARGE:
      saveState(storageKey.PAYMENT, action.payment);
      saveState(storageKey.CHARGING_IN_PROGRESS, true);

      return {
        ...state,
        statusText: chargingStatusText.PREPARING,
        chargingInProgress: true
      };
    case chargingActions.CHARGING_IN_PROGRESS:
      return {
        ...state,
        chargingInProgress: action.inProgress
      };
    case chargingActions.STATUS_QUERY_IN_PROGRESS:
      return {
        ...state,
        statusQueryInProgress: action.inProgress
      };
    case chargingActions.RESET_CHARGING:
      saveState(storageKey.CHARGING_IN_PROGRESS, false);
      return stopCharging(state);
    case chargingActions.STOP_CHARGING:
      saveState(storageKey.CHARGING_IN_PROGRESS, false);
      return stopCharging(state);
    case chargingActions.STOP_CHARGING_SUCCESS:
      return {
        ...state,
        status: action.status
      };
      case chargingActions.WAITING:
        return {
          ...state
        };
    default:
      return state;
  }
};
