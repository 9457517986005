import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  primaryText: {
    color: theme.text.primary,
    fontFamily: 'Montserrat',
  }
});

const PageHeaderComponent = ({ classes, label, headerVariant }) => (
  <Grid item align="center">
    <Typography variant={headerVariant} className={classes.primaryText}>
      {label}
    </Typography>
  </Grid>
);

PageHeaderComponent.propTypes = {
  label: PropTypes.string,
  headerVariant: PropTypes.string
};

PageHeaderComponent.defaultPropTypes = {
  label: "",
  headerVariant: "h6"
};

export default withStyles(styles)(PageHeaderComponent);
