import React from "react";
import Typography from "@material-ui/core/Typography";


const PrivacyPolicyContentEN = ( ) => {

  return (
    <>
    <Typography variant="body2">Processing of the personal data of Unified Chargers Oy’s electric car charging service customers</Typography>

    <Typography variant="subtitle1">WHO PROCESSES YOUR DATA?</Typography>
    <Typography variant="body2">Unified Chargers Oy (Business ID: 2851232-3)</Typography>
    <Typography variant="body2">Tel. +358 40 765 5038</Typography>

    <Typography variant="subtitle1">WHO CAN YOU CONTACT IN QUESTIONS RELATED TO DATA PROTECTION?</Typography>
    <Typography variant="body2">If you have any questions related to data protection or you wish to exercise your rights, you may contact:</Typography>
    <Typography variant="body2">Jukka Anttonen, CEO</Typography>
    <Typography variant="body2">jukka.anttonen@unifiedchargers.fi</Typography>

    <Typography variant="subtitle1">WHAT PERSONAL DATA DO WE PROCESS?</Typography>
    <Typography variant="body2">If you are our customer, we process the following personal data:</Typography>
    <ul>
      <li><Typography variant="body2">First and last name</Typography></li>
      <li><Typography variant="body2">Email address</Typography></li>
      <li><Typography variant="body2">Username and password</Typography></li>
      <li><Typography variant="body2">Payment information, such as credit card number, the total amount of the purchase and other information about the payment transaction</Typography></li>
    </ul>
    <Typography variant="body2">If you visit our website, we will process data collected by means of cookies on, inter alia, from where you are visiting our website, which parts of our website you are visiting, and which browser you are using. More information on the use of cookies is provided in our Cookie Policy.</Typography>

    <Typography variant="subtitle1">FOR WHAT PURPOSES AND ON WHAT GROUNDS DO WE PROCESS YOU PERSONAL DATA?</Typography>
    <Typography variant="body2">We process your personal data in order to manage your customer relationship. We also process your personal data for analysing, planning and developing our business and electric car charging services.</Typography>
    <Typography variant="body2">The basis for the processing of personal data is a customer relationship or the preparation of a contract, or our legitimate interest based on an actual or potential contractual relationship.</Typography>

    <Typography variant="subtitle1">FROM WHERE DO WE OBTAIN YOUR PERSONAL DATA?</Typography>
    <Typography variant="body2">We collect personal data primarily from yourself.</Typography>

    <Typography variant="subtitle1">WHO DO WE SHARE YOUR PERSONAL DATA WITH?</Typography>
    <Typography variant="body2">We do not share personal data with outsiders except where required by Finnish legislation or authorities. In the processing of personal data, we can use the following service providers which process your personal data on our behalf:</Typography>
    <ul>
      <li><Typography variant="body2">Stripe Payments Europe Ltd.</Typography></li>
    </ul>

    <Typography variant="subtitle1">DO WE TRANSFER YOUR PERSONAL DATA TO A THIRD COUNTRY?</Typography>
    <Typography variant="body2">We use payment transmission tools where your data is stored in the data servers located in the USA. We do not transfer personal data outside the EU or the EEA otherwise. The data transfers are based on the standard contractual clauses of the European Commission.</Typography>

    <Typography variant="subtitle1">HOW LONG DO WE STORE YOUR PERSONAL DATA?</Typography>
    <Typography variant="body2">We will store the personal data for as long as is necessary for the purposes for which they are processed or for complying with our legal obligations. We regularly review the necessity of keeping the data and delete data when they no longer are necessary for the purpose they were collected.</Typography>

    <Typography variant="subtitle1">COOKIES</Typography>
    <Typography variant="body2">Cookies are small files that are stored on a user's computer or other device while browsing the website. Cookies make it possible to, for example, measure the number of visitors. If you wish, you can restrict the use of cookies or prevent the use of cookies completely from the browser settings of your device. Please note that blocking cookies may affect the functionality of the website.</Typography>
    <Typography variant="body2">We use cookies to collect the following information:</Typography>
    <ul>
      <li><Typography variant="body2">The time of the visit to the website, the pages visited, and the time spent on each website</Typography></li>
      <li><Typography variant="body2">The site through which the website was accessed</Typography></li>
      <li><Typography variant="body2">Device type (mobile phone, tablet, computer)</Typography></li>
      <li><Typography variant="body2">Web browser type</Typography></li>
      <li><Typography variant="body2">Operating system type</Typography></li>
      <li><Typography variant="body2">Network location and IP address</Typography></li>
    </ul>
    <Typography variant="body2">We use analytics tools on our website, as well as session-specific cookies. The session cookie is temporary and is deleted as soon as providing the service (charging) is completed.</Typography>

    <Typography variant="subtitle1">WHAT RIGHTS DO YOU HAVE?</Typography>
    <Typography variant="body2">You can contact tietosuoja@unifiedchargers.fi to exercise your rights.</Typography>

    <Typography variant="subtitle1">Right of access</Typography>
    <Typography variant="body2">You have the right to obtain from us a confirmation as to whether we process personal data concerning you. You also have the right to access personal data concerning you as well as information on the processing of your personal data as set out in the General Data Protection Regulation.</Typography>
    <Typography variant="body2">If you exercise your right to access the information, we will provide you with a copy of the personal data we process. If you request multiple copies, we may charge a reasonable fee for them based on administrative costs.</Typography>

    <Typography variant="subtitle1">Right to rectification</Typography>
    <Typography variant="body2">You have the right to request us to rectify any incorrect data without undue delay. You also have the right to have incomplete personal data completed by providing a supplementary statement.</Typography>

    <Typography variant="subtitle1">Right to erasure</Typography>
    <Typography variant="body2">You have the right to obtain from us the erasure of personal data concerning you without undue delay if:</Typography>
    <ul>
      <li><Typography variant="body2">your personal data are no longer needed for the purposes for which they were collected or otherwise processed;</Typography></li>
      <li><Typography variant="body2">you withdraw consent on which the processing is based and there are no other legal grounds for the processing of such data;</Typography></li>
      <li><Typography variant="body2">you object to the processing of your personal data on ground relating to your particular situation and there are no legitimate grounds for processing, or you oppose the processing of your personal data for direct marketing purposes;</Typography></li>
      <li><Typography variant="body2">we have processed personal data unlawfully; or</Typography></li>
      <li><Typography variant="body2">personal data have to be erased for compliance with a legal obligation we are subject to.</Typography></li>
    </ul>

    <Typography variant="subtitle1">Right to restriction of processing</Typography>
    <Typography variant="body2">You have the right to obtain from us restriction of processing so that your personal data may, with the exception of storage, be processed only with your consent or for the establishment, exercise or defence of legal claims or to protect the rights of another person if:</Typography>
    <ul>
      <li><Typography variant="body2">you contest the accuracy of your personal data, in which case we will restrict processing for a period enabling us to verify the accuracy of your personal data;</Typography></li>
      <li><Typography variant="body2">we process your personal data unlawfully and you oppose the erasure of the personal data and request the restriction of their use instead;</Typography></li>
      <li><Typography variant="body2">we no longer need your personal data for the purposes of the processing, but you require them for the establishment, exercise or defence of legal claims; or </Typography></li>
      <li><Typography variant="body2">you have objected to the processing of your personal data on grounds relating to your particular situation and you wait for the verification whether our legitimate grounds override the grounds of your objection.</Typography></li>
    </ul>

    <Typography variant="subtitle1">Right to data portability</Typography>
    <Typography variant="body2">You have the right to receive the personal data provided to us by you in a structured, commonly used and machine-readable format and the right to transmit those data to another controller if:</Typography>
    <ul>
      <li><Typography variant="body2">our processing is carried out by automated means; and</Typography></li>
      <li><Typography variant="body2">the processing is based on your consent or is necessary for the performance of our agreement or for the implementation of pre-contractual measures upon your request.</Typography></li>
    </ul>
    <Typography variant="body2">The right to data portability is limited to conduct which does not adversely affect the rights and freedoms of others.</Typography>

    <Typography variant="subtitle1">Right to object to processing of personal data</Typography>
    <Typography variant="body2">You have the right to object to the processing of your personal data on grounds relating to your particular situation if there are no legitimate grounds for the processing.</Typography>

    <Typography variant="subtitle1">RIGHT TO WITHDRAW CONSENT</Typography>
    <Typography variant="body2">To the extent that the processing of personal data is based on your consent, you have the right to withdraw your consent to the processing of personal data at any time. Withdrawal of consent does not affect the lawfulness of the processing we have carried out prior to withdrawal.</Typography>

    <Typography variant="subtitle1">RIGHT TO LODGE A COMPLAINT WITH A SUPERVISORY AUTHORITY</Typography>
    <Typography variant="body2">You have the right to lodge a complaint with the Data Protection Ombudsman if you think that your rights under the General Data Protection Regulation have been infringed in the processing of the personal data. The supervising authority in Finland is Data Protection Ombudsman (www.tietosuoja.fi).</Typography>

    <Typography variant="subtitle1">HOW DO WE ENSURE DATA SECURITY IN THE PROCESSING OF PERSONAL DATA?</Typography>
    <Typography variant="body2">We store your personal data in systems which are protected with firewalls, personal user rights and passwords and other technical and organizational measures generally accepted in the field at the time.</Typography>
    <Typography variant="body2">Only those of our personnel who need to process your personal data to perform their working duties have access to your personal data.</Typography>

    </>
  );
};

export default PrivacyPolicyContentEN;
