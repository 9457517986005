import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar } from "react-circular-progressbar";
import Typography from "@material-ui/core/Typography";
import "react-circular-progressbar/dist/styles.css";
import { withStyles } from "@material-ui/core/styles";

function CustomContentProgressbar(props) {
  const { children, ...otherProps } = props;

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%"
      }}
    >
      <div style={{ position: "absolute" }}>
        <CircularProgressbar {...otherProps} />
      </div>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

const styles = theme => ({
  percentageText: {
    color: theme.text.primary,
    fontSize: "2em"
  },
  estimationTitleText: {
    color: theme.text.secondary,
    fontSize: "0.8em"
  },
  estimationText: {
    color: theme.text.primary,
    fontSize: "0.8em"
  }
});

class ChargingIndicator extends React.Component {
  state = {
    progress: 0,
    width: 0,
    height: 0
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions());
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  render() {
    const { classes, progress, estimatedTimeToFull } = this.props;
    const { width } = this.state;

    // iPad Pro dimensions disrupts the width / height ratio of the indicator
    const fixedSizeWithVeryLargeDisplays = width > 1000;

    return (
      <div
        style={{
          width: fixedSizeWithVeryLargeDisplays ? "250px" : "30vh",
          height: fixedSizeWithVeryLargeDisplays ? "250px" : "30vh"
        }}
      >
        <CustomContentProgressbar
          value={progress}
          strokeWidth={4}
          styles={{
            path: { stroke: "rgb(8, 253, 98)" },
            trail: { stroke: "rgb(58, 60, 79)" }
          }}
        >
          <Typography
            variant="h4"
            className={classes.percentageText}
          >
            {progress}%
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.estimationTitleText}
          >
            Estimated time to full
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.estimationText}
          >
            {estimatedTimeToFull} min
          </Typography>
        </CustomContentProgressbar>
      </div>
    );
  }
}

ChargingIndicator.propTypes = {
  progress: PropTypes.number,
  estimatedTimeToFull: PropTypes.number
};

ChargingIndicator.defaultPropTypes = {
  progress: 0,
  estimatedTimeToFull: 255
};

export default withStyles(styles)(ChargingIndicator);
