import moment from "moment";

export function getElapsedTime(startTime) {
  if (startTime) {
    return moment().diff(startTime, "minutes");
  }
  return 0;
}

export function getDistanceBetweenTwoCoordinates(lat1, lon1, lat2, lon2) {
  var p = 0.017453292519943295;
  var c = Math.cos;
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a));
}
