// This whole file is not used at the moment, all storage handling
// could be processed through here

export const loadState = key => {
	if (typeof key !== "undefined" && typeof key === "string") {
		try {
			const payment = localStorage.getItem(key);

			if (payment === null) {
				return undefined;
			}

			const serializedState = {
				payment: JSON.parse(payment),
			};

			return serializedState;
		} catch (err) {
			return undefined;
		}
	}

	return "";
};

export const storageKey = {
	PAYMENT: "payment",
	CHARGING_IN_PROGRESS: "chargingInProgress"
};

export const saveState = (key, object) => {
  try {
    if (object) {
			const serializedObject = JSON.stringify(object);
			localStorage.setItem(key, serializedObject);
    } else {
      localStorage.setItem(key, object);
    }
  } catch (err) {
		// Ignore write errors.
	}
};

export const clearState = () => {
	localStorage.clear();
};
