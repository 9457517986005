export function themeHasErrored(bool) {
  return {
      type: 'THEME_HAS_ERRORED',
      hasErrored: bool
  };
}

export function themeIsLoading(bool) {
  return {
      type: 'THEME_IS_LOADING',
      isLoading: bool
  };
}

export function themeFetchDataSuccess(theme) {
  return {
      type: 'THEME_FETCH_DATA_SUCCESS',
      theme
  };
}

export function themeFetchData() {
  return (dispatch) => {
      dispatch(themeIsLoading(true));
      fetch("/themes/theme-config.json")
          .then((response) => {
              if (!response.ok) {
                  dispatch(themeHasErrored(true));
                  throw Error(response.statusText);
              }
              return response;
          })
          .then((response) => response.json())
          .then((theme) => dispatch(themeFetchDataSuccess(theme)))
          .then(() => dispatch(themeIsLoading(false)))
          .catch(() => dispatch(themeHasErrored(true)));
  };
}
