import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import HeaderWithLines from "./HeaderWithLines";
import { connect } from "react-redux";

const styles = () => ({
  logoArea: {
    backgroundColor: "inherit",
    boxShadow: "none",
  },
  logo: {
    width: "100%"
  }
});

const Logo = ({ theme, classes, subHeading }) => {
  return (
    <Paper className={classes.logoArea} elevation={1}>
    <img className={classes.logo} src={theme.logoUrl} alt="logo" />
    {subHeading && (
      <HeaderWithLines
        header={subHeading}
        height={10}
        width={60}
        textColor="#9fa4b0"
        lineColor="#9fa4b0"
      />
    )}
    </Paper>
  );
};


Logo.propTypes = {
  subHeading: PropTypes.string
};

Logo.defaultPropTypes = {
  subHeading: "Station details"
};

const mapStateToProps = (state) => {
  return {
      theme: state.theme,
      themeHasErrored: state.themeHasErrored,
      themeIsLoading: state.themeIsLoading
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Logo));

