import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    color: "#FFF"
  },
  numberText: {
    color: theme.text.accent,
    fontSize: "40px",
    fontWeight: "700"
  },
  labelText: {
    color: theme.text.primary,
    fontSize: "24px",
    fontWeight: "700",
    paddingTop: "12px"
  }
});

const Power = ({ amount, unit, classes }) => (
    <Grid container spacing={2}>
      <Grid item xs={6} align="right">
        <Typography className={classes.numberText}>
          {amount}
        </Typography>
      </Grid>
      <Grid item xs={6} align="left">
        <Typography className={classes.labelText}>
          {unit}
        </Typography>
      </Grid>
    </Grid>
);

export default withStyles(styles)(Power);
