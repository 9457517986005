import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TopNavigationBar from "../containers/TopNavigationBar";
import Routes from "./Routes";
import { createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { themeFetchData } from "../../actions/themeloader";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      muiTheme: null
    };
  }

  async componentDidMount() {
    await this.props.fetchThemeFromConfig();
  }

  render() {
    const { theme, themeIsLoading } = this.props;
    const muiTheme = createMuiTheme(theme);

    return (
      (themeIsLoading || Object.keys(theme).length === 0)
      ? <div></div>
      :
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <TopNavigationBar logoUrl={theme.logoUrl} vendorUrl={theme.vendorUrl}/>
        <Routes {...this.props} />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      theme: state.theme,
      themeHasErrored: state.themeHasErrored,
      themeIsLoading: state.themeIsLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchThemeFromConfig: () => dispatch(themeFetchData())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
