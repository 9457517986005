export const HIDE_TOP_BAR = "HIDE_TOP_BAR";
export const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";
export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";

export const hideTopBar = hidden => ({
  type: HIDE_TOP_BAR,
  hidden
});

export const toggleNavigationMenu = isOpen => ({
  type: TOGGLE_NAVIGATION,
  isOpen
});

export const changeActiveTabIndex = activeTabIndex => ({
  type: CHANGE_ACTIVE_TAB,
  activeTabIndex
});
