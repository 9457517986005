import { SHOW_DIALOG } from "../actions/dialog";

const initialState = {
	showDialog: false
};

export default (state = initialState, action) => {
	switch (action.type) {
	case SHOW_DIALOG:
		return {
			...state,
			showDialog: action.value
		};
	default:
		return state;
	}
};
