import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InfoItem from "./InfoItem";

const InfoRow = ({ content, classes }) => (
  <Grid container spacing={0}>
    <Grid item xs={4} style={{ paddingRight: "2px" }}>
      <Paper
        className={classes.paper}
        square
        style={{ borderRadius: "6px 0 0 6px", padding: "8px" }}
      >
        <InfoItem itemContent={content[0]} classes={classes} />
      </Paper>
    </Grid>
    <Grid item xs={4} style={{ paddingRight: "2px" }}>
      <Paper className={classes.paper} square style={{ padding: "8px" }}>
        <InfoItem itemContent={content[1]} classes={classes} />
      </Paper>
    </Grid>
    <Grid item xs={4} style={{ paddingRight: "2px" }}>
      <Paper
        className={classes.paper}
        square
        style={{ borderRadius: "0 6px 6px 0", padding: "8px" }}
      >
        <InfoItem itemContent={content[2]} classes={classes} />
      </Paper>
    </Grid>
  </Grid>
);

export default InfoRow;
