import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const styles = { margin: "0 8px 0 8px", color: "#9fa4b0", fontFamily: 'Montserrat', fontWeight: "600", fontSize: "14px" };

const Line = ({ height, width, color }) => (
  <svg height={height} width={width}>
    <line
      x1="0"
      y1={height / 2}
      x2={width}
      y2={height / 2}
      style={{ stroke: color, strokeWidth: 2 }}
    />
  </svg>
);

const HeaderWithLines = ({ header, height, width, textColor, lineColor }) => (
  <Typography variant="subtitle2" style={{ color: lineColor }}>
    <Line height={height} width={width} color={textColor} />
    <span style={styles}>{header}</span>
    <Line height={height} width={width} color={textColor} />
  </Typography>
);

HeaderWithLines.propTypes = {
  header: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  textColor: PropTypes.string,
  lineColor: PropTypes.string
};

HeaderWithLines.defaultPropTypes = {
  height: 10,
  width: 60,
  textColor: styles.color,
  lineColor: styles.color
};

export default HeaderWithLines;
