import React from "react";
import Typography from "@material-ui/core/Typography";

const InfoItem = ({ itemContent, classes }) => (
  <div>
    <Typography variant="subtitle1" className={classes.text} noWrap>
      {itemContent.title}
    </Typography>
    <Typography variant="h5" className={classes.value}>
      {itemContent.amount}
    </Typography>
    <Typography variant="subtitle1" className={classes.text}>
      {itemContent.unit}
    </Typography>
  </div>
);

export default InfoItem;
