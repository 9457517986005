import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Spinner from "../shared/Spinner";
import PageHeader from "../shared/PageHeader";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import ChargingIndicator from "../charging/ChargingIndicator";
import InfoRow from "../charging/InfoRow";
import { toggleDialog } from "../../actions/dialog";
import { hideTopBar } from "../../actions/navigation";

import { chargingAction } from "../../actions/charging";
import { storageKey } from "../../localStorage";
import TimeoutCalculator from "../shared/TimeoutCalculator";

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none"
  },
  card: {
    backgroundColor: "inherit",
    boxShadow: "none",
    height: "auto"
  },
  button: {
    margin: theme.spacing(1)
  },
  media: {
    height: 51
  },
  greenLink: {
    color: theme.palette.text.primary
  },
  text: {
    color: theme.text.secondary,
    fontWeight: 0.2,
    fontSize: "15px"
  },
  value: {
    color: theme.text.secondary,
    fontWeight: 0.2
  }
});

const getContent = ({ chargingStatus, elapsedTime }) => {
  const energyDelivered = chargingStatus.energyDelivered || 0;
  const priceTotalCents = chargingStatus.priceTotalCents / 100 || 0;
  const powerReading = chargingStatus.powerReading || 0;
  const voltageReading = chargingStatus.voltageReading || 0;
  const currentReading = chargingStatus.currentReading || 0;

  const energyFormat = energyDelivered && !isNaN(energyDelivered) ? energyDelivered / 1000 : 0.00;
  const parsedEnergyDelivered = parseFloat(energyFormat.toFixed(2));

  return {
    row1: [
      {
        title: "Time elapsed",
        amount: elapsedTime || 0,
        unit: "min"
      },
      {
        title: "Energy",
        amount: parsedEnergyDelivered,
        unit: "kWh"
      },
      {
        title: "Price",
        amount: priceTotalCents,
        unit: "€"
      }
    ],
    row2: [
      {
        title: "Power",
        amount: powerReading,
        unit: "kW"
      },
      {
        title: "Voltage",
        amount: voltageReading,
        unit: "V"
      },
      {
        title: "Current",
        amount: currentReading,
        unit: "A"
      }
    ]
  };
};

class ChargingView extends Component {
  state = {
    stopping: false
  };

  componentDidMount() {
    const payment = localStorage.getItem(storageKey.PAYMENT);
    const serializedPayment = JSON.parse(payment);

    const chargingInProgress = localStorage.getItem(storageKey.CHARGING_IN_PROGRESS);

    if (serializedPayment && chargingInProgress === "true") {
      this.props.setChargingInProgress(true);
      this.props.getStatus(serializedPayment); 
    } else {
      this.props.push('/summary');
    }
  }

  handleChange = () => {
    this.props.toggleDialog("stopCharging");
  };

  render() {
    const {
      classes,
      chargingStatus,
      toggleDialog,
      showDialog,
      elapsedTime
    } = this.props;

    const content = getContent({ chargingStatus, elapsedTime });
    const percent = chargingStatus.chargedPercent || 0;
    const estimatedTimeToFull =
      chargingStatus.estimatedTimeInMinutesToBeFullyCharged || 255;
    const {stopping} = this.state;

    return (
      <div className={classes.root}>
        <ConfirmationDialog
          title="Stop charging"
          buttonLabels={["Cancel", "Stop"]}
          showDialog={showDialog}
          toggleDialog={toggleDialog}
          passValue={stopCharging => {
            if (stopCharging) {
              this.setState({ stopping: true });
              const payment = localStorage.getItem("payment");
              const serializedPayment = JSON.parse(payment);
              this.props.stopCharging(serializedPayment);
            }
          }}
        />
        {stopping ? (
        <Grid container>
          <Grid item xs={12} align="center">
            <PageHeader title={this.props.chargingStatusText} />
          </Grid>

          <Grid item xs={10} align="center">
            <Spinner />
          </Grid>
      </Grid>
        ) : (
          <Grid container style={{ height: "100vh" }}>
          <Grid item xs={12} align="center">
            <PageHeader title={this.props.chargingStatusText} />
            <TimeoutCalculator title={this.props.chargingStatusText} />
          </Grid>
          <Grid item xs={12} align="center">
            <ChargingIndicator
              progress={percent}
              estimatedTimeToFull={estimatedTimeToFull}
            />
          </Grid>

          <Grid item xs={12} align="center">
            <InfoRow content={content.row1} classes={classes} />
          </Grid>
          <Grid item xs={12} align="center">
            <InfoRow content={content.row2} classes={classes} />
          </Grid>

          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => this.props.toggleDialog(true)}
              style={{ backgroundColor: "#ac3439", color: "white" }}
            >
              Stop charging
            </Button>
          </Grid>
        </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showDialog: state.dialog.showDialog,
  chargingStatus: state.charging.status,
  chargingStatusText: state.charging.statusText,
  elapsedTime: state.charging.elapsedTime,
  chargingInProgress: state.charging.chargingInProgress
});

const mapDispatchToProps = dispatch => ({
  toggleDialog: value => dispatch(toggleDialog(value)),
  hideTopBar: value => dispatch(hideTopBar(value)),
  // initializeCharge: () => dispatch(chargingAction.initializeCharge()),
  setChargingInProgress: inProgress => dispatch(chargingAction.setChargingInProgress(inProgress)),
  stopCharging: serializedPayment => dispatch(chargingAction.stopCharging(serializedPayment)),
  getStatus: payment => dispatch(chargingAction.getStatus(payment)),
  push: directory => dispatch(push(directory))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChargingView));
