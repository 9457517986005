import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import { ReactComponent as CCSIcon } from "../../images/CCS.svg";
import { ReactComponent as CHAdeMOIcon } from "../../images/CHAdeMO.svg";
import { ReactComponent as Type2Icon } from "../../images/type_2.svg";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    boxShadow: "none"
  },
  table: {
    whiteSpace: "nowrap"
  },
  tableCell: {
    padding: "default",
    color: theme.palette.text.primary
  },
  greenText: {
    color: theme.text.accent
  },
  whiteText: {
    color: theme.text.primary
  },
  text: {
    color: theme.text.primary
  },
  headerText: {
    color: theme.text.white,
    fontWeight: 700,
    fontSize: "15px",
    paddingBottom: "8px"
  },
  innertext: {
    color: theme.text.white,
    fontWeight: 400,
    fontSize: "15px",
  },
  inactiveText: {
    color: theme.text.inactive,
    fontWeight: 700,
    fontSize: "15px",
  },
  activeBox: {
    color: theme.palette.activeBackgroud
  },
  activeImage:{
    color: theme.palette.icons.main,
    stroke: "none",
    width: "22px",
    height: "22px",
    marginRight: "5px"
  },
  inUseImage:{
    color: theme.palette.inUseBackgroud.main,
    stroke: "none",
    width: "22px",
    height: "22px",
    marginRight: "5px"
  },
  inactiveImage:{
    color: theme.palette.unActiveBackgroud.main,
    stroke: "none",
    width: "22px",
    height: "22px",
    marginRight: "5px"
  },
  paper: {
    backgroundColor: theme.palette.activeBackgroud.main,
    padding: "8px",
  }
});

const GetConnectorStatus = ({chargingUnit ,classes}) => {

  const statusAvailable = classes.activeImage;
  const statusInuse = classes.inUseImage;
  const statusUnavailable = classes.inactiveImage;

  let status = statusAvailable;
  if (chargingUnit.chargingUnitId.includes('Elinta')){
    if (chargingUnit.available && ((chargingUnit.elintaConnectorStatus >= 3 && chargingUnit.elintaConnectorStatus <= 5) || chargingUnit.elintaConnectorStatus == 7)){
      status = statusAvailable;
    }
    else if (chargingUnit.elintaConnectorStatus == 6) {
      status = statusInuse;
    }
    else if (!chargingUnit.available && (chargingUnit.elintaConnectorStatus == 6 || chargingUnit.elintaConnectorStatus == 7)){
      status = statusInuse;
    }
    else if (!chargingUnit.available || (chargingUnit.elintaConnectorStatus < 3 || chargingUnit.elintaConnectorStatus > 7) ){
      status = statusUnavailable;
    }
  }
  else {
    status = chargingUnit.available ? statusAvailable : statusInuse;
  }
  return status;
}

const ChargingUnit = ({ chargingUnit, classes }) => {
  if (chargingUnit === undefined) return null;
  let connectorStatus = GetConnectorStatus({chargingUnit, classes});
  let image = null;
  switch (chargingUnit.standard){
    case "CHAdeMO":
      image = (<CHAdeMOIcon className={connectorStatus} />);
    break;
    case "CCS":
      image = (<CCSIcon className={connectorStatus} />);
    break;
    case "Type 2":
    default:
      image = (<Type2Icon className={connectorStatus} />);
    break;
  }

  return connectorStatus == classes.activeImage || connectorStatus == classes.inUseImage ? (
          <Grid container spacing={0} direction='row' align="left">
            <Grid item>
              {image}
            </Grid>
            <Grid item>
              <Typography className={classes.innertext}>
                {chargingUnit.standard}
              </Typography>
            </Grid>
          </Grid> ) : (
          <Grid container spacing={0} direction='row' align="left">
            <Grid item>
              {image}
            </Grid>
            <Grid item>
              <Typography className={classes.inactiveText}>
                {chargingUnit.standard}
              </Typography>
            </Grid>
        </Grid> );
};

const Station = ({ station, stationNumber, classes, clickHandler }) => {
  let available = null;
  if (station == undefined) return available;
  available = (
    <Paper
      className={classes.paper}
      square={false}
      key={station.stationId}
      onClick={() => clickHandler(station)}
    >
    <Grid item xs={12} >
      <Grid item xs={12} >
        <Typography className={classes.headerText}>
          Station {station.stationNumber}
        </Typography>
      </Grid>
      <Grid item xs={12} >
        <ChargingUnit classes={classes} chargingUnit={station.chargingUnits[0]} />
      </Grid>
      <Grid item xs={12} >
        <ChargingUnit classes={classes} chargingUnit={station.chargingUnits[1]} />
      </Grid>
    </Grid>
    </Paper>
  );
  return available;
};


function StationsListItem(props) {
  const { classes, station, clickHandler } = props;

  return (

    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Station classes={classes} station={station} clickHandler={clickHandler} />
      </Grid>
    </Grid>
  );
}

StationsListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  station: PropTypes.object,
  stationNumber: PropTypes.number
};

export default withStyles(styles)(StationsListItem);
