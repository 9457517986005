import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default class FormDialog extends React.Component {
  state = {};

  handleClose(save) {
    if (save) {
      this.props.passValue(save);
    }
    this.props.toggleDialog(false);
  }

  handleChange = value => event => {
    this.setState({
      [value]: event.target.value
    });
  };

  render() {
    const { showDialog, title, buttonLabels } = this.props;

    return (
      <div>
        <Dialog
          open={showDialog}
          onClose={() => this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Stop charging and proceed to summary?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              {buttonLabels[0]}
            </Button>

            <Button
              onClick={() => this.handleClose("save")}
              style={{ color: "red" }}
            >
              {buttonLabels[1]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FormDialog.propTypes = {
  title: PropTypes.string,
  showDialog: PropTypes.bool,
  buttonLabels: PropTypes.arrayOf(PropTypes.string)
};

FormDialog.defaultProps = {
  title: "",
  showDialog: false,
  buttonLabels: ["Cancel", "Confirm"]
};
