import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createHashHistory } from "history";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import axios from "axios";
import App from "./components/app/App";
import rootReducer from "./reducers";
import * as serviceWorker from "./serviceWorker";
import { loadState } from "./localStorage";
import createSagaMiddleware from "redux-saga";
import chargingSaga from "./saga/charging";

const history = createHashHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState("payment");

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

const store = createStore(
  rootReducer(history),
  persistedState,
  composeEnhancer(applyMiddleware(...middlewares, routerMiddleware(history)))
);

sagaMiddleware.run(chargingSaga);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["api-key"] = "123";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
