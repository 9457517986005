import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  numberText: {
    color: theme.text.accent,
    fontSize: "46px",
    fontFamily: "Montserrat",
    fontWeight: "700"
  }
});

const StationNumber = ({ classes, number }) => (
  <Fragment>
    <Grid item xs={12} align="right">
      <Typography className={classes.numberText}>
        {number}
      </Typography>
    </Grid>
  </Fragment>
);

export default withStyles(styles)(StationNumber);
