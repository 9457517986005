import { HIDE_TOP_BAR } from "../actions/navigation";
import { TOGGLE_NAVIGATION, CHANGE_ACTIVE_TAB } from "../actions/navigation";

const menuLinks = [{ label: "Home", path: "/" }, { label: "Privacy policy", path: "/privacy"}];

const initialState = {
  isOpen: false,
  activeTabIndex: 0,
  menuLinks,
  title: "Stations",
  hidden: false
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case HIDE_TOP_BAR:
      return {
        ...state,
        hidden: action.hidden
      };
    case TOGGLE_NAVIGATION:
      return {
        ...state,
        isOpen: !action.isOpen
      };
    case CHANGE_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.activeTabIndex
      };
    default:
      return state;
  }
}
