import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NearMe from "@material-ui/icons/NearMe";
import LocationOnRounded from "@material-ui/icons/LocationOnRounded"
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    color: theme.text.primary
  },
  text: {
    color: theme.text.primary
  },
  headerText: {
    color: theme.text.primary,
    fontWeight: 700,
    fontSize: "16px"
  },
  innertext: {
    color: theme.text.primary,
    fontWeight: 400,
    fontSize: "16px"
  },
  distanceText: {
    color: theme.text.primary,
    fontWeight: 700,
    fontSize: "12px"
  },
  icons:{
    color: theme.text.accent,
    width: "18px",
    height: "18px",
  },
  smallIcons:{
    color: theme.text.accent,
    width: "15px",
    height: "17px",
    paddingTop: "4px",
    marginRight: "5px"
  },
  noWrap:{
    whiteSpace: "nowrap"
  }
});

class StationAreaInfo extends Component {
  render() {
    const { stationArea, classes } = this.props;
    const details = {
      name: stationArea.name,
      address: stationArea.address,
      location: {
        latitude: stationArea.latitude || 0,
        longitude: stationArea.longitude || 0
      },
      distance: stationArea.distance + " km",
    };

    return (
      <Grid container spacing={0}>
        <Grid item xs={9} >
          <Typography className={classes.headerText}>
            {details.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="row" justify="flex-end">
            <Grid item>
              <NearMe className={classes.icons} />
            </Grid>
            <Grid item>
              <Typography className={classes.distanceText}>
                {details.distance}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={0} direction='row'>
            <Grid item>
            <LocationOnRounded className={classes.smallIcons} />
            </Grid>
            <Grid item>
            <Typography className={classes.innertext}>
              {details.address}
            </Typography>
            </Grid>
          </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(StationAreaInfo);
