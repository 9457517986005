import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Spinner from "../shared/Spinner";
import HeaderWithLines from "../shared/HeaderWithLines";
import ChargingSummary from "../summary/ChargingSummary";
import PricingSummary from "../summary/PricingSummary";
import Logo from "../shared/Logo";
import { chargingAction } from "../../actions/charging";
import { storageKey } from "../../localStorage";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "inherit",
    color: "white",
    boxShadow: "none"
  },
  text: {
    color: theme.text.primary,
    marginTop: "20px"
  },
  notificationText: {
    color: theme.palette.secondary.main
  },
  failureText: {
    color: "red"
  },
  setPadding:{
    paddingBottom: "10px",
    paddingTop: "15px"
  }
});

class PaymentView extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const payment = localStorage.getItem(storageKey.PAYMENT);
    const serializedPayment = JSON.parse(payment);
    const chargingInProgress = localStorage.getItem(storageKey.CHARGING_IN_PROGRESS);

    if (serializedPayment && chargingInProgress === "true") { // redirect to charging page if charging in progress
      this.props.push('/charging');
    } else if (serializedPayment && serializedPayment.transactionId) { // get status if charging has been ended and transaction id is known
      this.props.getStatus(serializedPayment);
    } else {
      this.props.push("/"); // otherwise redirect to home page
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chargingStatus !== this.props.chargingStatus) {
    }
  }

  onClickBackToHome = () => {
    this.props.clearChargingStatus();
    this.props.push("/");
  }

  render() {
    const { chargingStatus, classes, elapsedTime } = this.props;
    const { loading } = this.state;
    if (loading) {
      if (chargingStatus && Object.keys(chargingStatus).length > 0) {
        this.setState({ loading: false });
      }
    }
    return (
      <div className={classes.root}>
        {loading ? (
          <Grid container>
            <Grid item xs={10} align="center">
              <Spinner />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} align="center" style={{ padding: "20px" }}>
              <Grid item xs={12} md={6} align="center">
                <Logo />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" className={classes.setPadding}>
              <HeaderWithLines
                header={"Summary"}
                height={10}
                width={60}
                textColor="#9fa4b0"
                lineColor="#9fa4b0"
              />
            </Grid>

            <Grid item xs={12} align="center">
              <ChargingSummary content={{ chargingStatus, elapsedTime }} />
            </Grid>

            <Grid item xs={12} align="center" className={classes.setPadding}>
              <HeaderWithLines
                header={"Price"}
                height={10}
                width={60}
                textColor="#9fa4b0"
                lineColor="#9fa4b0"
              />
            </Grid>

            <Grid item xs={12} align="center">
              <PricingSummary content={chargingStatus} />
            </Grid>

            {chargingStatus.chargingScenarioText && (
              <Grid item xs={12} align="center">
                <Typography
                  variant="subtitle1"
                  className={classes.notificationText}
                >
                  {chargingStatus.chargingScenarioText}
                </Typography>
              </Grid>
            )}

            {chargingStatus.failureMessage && (
              <Grid item xs={12} align="center">
                <Typography variant="subtitle1" className={classes.failureText}>
                  Something went wrong
                  {/* {chargingStatus.failureMessage} */}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} align="center">
              <Typography variant="h4" className={classes.text}>
                Thank you!
              </Typography>
            </Grid>

            <Grid item xs={12} align="center" onClick={this.onClickBackToHome}>
              <Typography variant="subtitle1" className={classes.text}>
                <u>Back to home screen</u>
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  amount: state.payment.amount,
  payment: state.payment.paymentInfo,
  chargingStatus: state.charging.status,
  isLoading: state.payment.isLoading,
  elapsedTime: state.charging.startTime != undefined && state.charging.endTime != undefined ? state.charging.endTime.diff(state.charging.startTime, "minutes") : 0
});

const mapDispatchToProps = dispatch => ({
  clearChargingStatus: () => dispatch(chargingAction.clearChargingStatus()),
  push: directory => dispatch(push(directory)),
  getStatus: payment => dispatch(chargingAction.getStatus(payment)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentView));
