import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  textField: {
    backgroundColor: "white",
    borderRadius: "6px",
    margin: "2px 0px 8px 0px",
    fontFamily: 'Montserrat'
  }
});

const SearchFilter = ({ classes, items, handleChange }) => (
  <TextField
    className={classes.textField}
    id="filter"
    type="search"
    placeholder="Filter by station name, address or plug type"
    variant="outlined"
    //margin="normal"
    onChange={e => handleChange(e, items)}
    fullWidth
  />
);

SearchFilter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchFilter);
