import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import HeaderWithLines from "../shared/HeaderWithLines";
import Power from "../station/Power";
import Prices from "../station/Prices";
import ChargingUnits from "../station/ChargingUnits";
import StationInfo from "../station/StationInfo";
import { selectCharger } from "../../actions/charger";
import {
  fetchStationAreas,
  storeSelectedStation
} from "../../actions/stations";
import Logo from "../shared/Logo";

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1
  },
  logo: {
    backgroundColor: "inherit",
    boxShadow: "none"
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "left",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none"
  },
  primaryText: {
    color: theme.text.primary
  },
  chargerPadding: {
    padding: "15px 30px 0px 30px"
  },
  linePadding:{
    padding: "10px 0px 10px 0px"
  }
});

class StationView extends Component {

  componentDidMount() {
    let {selectedStation, theme } = this.props;
    if (!selectedStation && theme) {
      this.props.fetchStationAreas(this.props.theme.vendorId);
    }
  }

  componentDidUpdate(prevProps) {
    let { stationAreas } = this.props;
    if (stationAreas) {
      let id = this.getStationIdFromRoute();
      let foundStation = this.getStation(stationAreas, id);
      this.props.storeSelectedStation(foundStation);
    }
  }

  getStationIdFromRoute() {
    return this.props.pathname.substring(this.props.pathname.lastIndexOf('/') + 1);
  }

  getStation(stationAreas, stationId) {
    let foundStation = null;
    stationAreas.forEach(stationArea => {
      stationArea.stations.forEach(station => {
        if (station.stationId === stationId) {
          if (station.address == null) station.address = stationArea.address;
          foundStation = station;
          return;
        }
      });
    });
    return foundStation;
  }

  getStationAreaName(stationId) {
    let name = null;
    let { stationAreas } = this.props;
    if (stationAreas) {
      stationAreas.forEach(stationArea => {
        stationArea.stations.forEach(station => {
          if (station.stationId === stationId) {
            name = stationArea.name;
            return;
          }
        });
      });
    }
    return name;
  }

  getStationAreaAddress(stationId) {
    let address = null;
    let { stationAreas } = this.props;
    if (stationAreas) {
      stationAreas.forEach(stationArea => {
        stationArea.stations.forEach(station => {
          if (station.stationId === stationId) {
            address = stationArea.address;
            return;
          }
        });
      });
    }
    return address;
  }

  render() {
    const { isLoading, classes, selectCharger, selectedStation, push, stationAreaName } = this.props;
    const name = selectedStation ? this.getStationAreaName(selectedStation.stationId) : "";
    const address = selectedStation ? this.getStationAreaAddress(selectedStation.stationId) : "";
    const units =
    selectedStation && selectedStation.chargingUnits.map(cu => cu).sort((a, b) => a.number - b.number);

    return (
      <div className={classes.root}>
      { (!isLoading && selectedStation ) &&
        <Grid container style={{ height: "80vh" }} align="center">
          <Grid item xs={12} align="center">
            <Grid item xs={12} md={6} className={classes.linePadding}>
              <HeaderWithLines
                header={"Station details"}
                height={10}
                width={60}
                textColor="#9fa4b0"
                lineColor="#9fa4b0"
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <StationInfo
                  stationNumber={selectedStation.stationNumber}
                  name={name}
                  address={address}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.linePadding}>
              <HeaderWithLines
                header={"Power"}
                height={10}
                width={60}
                textColor="#9fa4b0"
                lineColor="#9fa4b0"
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Power
                  amount={selectedStation.maxPower || 0}
                  unit="kW"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.linePadding}>
              <HeaderWithLines
                header={"Price"}
                height={10}
                width={60}
                textColor="#9fa4b0"
                lineColor="#9fa4b0"
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Prices station={selectedStation} />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.linePadding}>
              <HeaderWithLines
                header={"Select charger"}
                height={10}
                width={60}
                textColor="#9fa4b0"
                lineColor="#9fa4b0"
              />
            </Grid>
            <Grid className={classes.chargerPadding}>
              <Grid item xs={false} sm={2} md={4}/>
              <Grid item xs={12} sm={8} md={4}>
                {units && (
                  <ChargingUnits
                    units={units}
                    selectCharger={chargingUnit => selectCharger(chargingUnit)}
                    push={directory => push(directory)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      </div>
      );
  }
}

StationView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  theme: state.theme,
  pathname: state.router.location.pathname,
  selectedStation: state.selections.selectedStation,
  stationAreas: state.data.stationAreas,
  isLoading: state.data.isLoading,
  stationAreaName: state.data.stationAreaName
});

const mapDispatchToProps = dispatch => ({
  selectCharger: chargingUnit => dispatch(selectCharger(chargingUnit)),
  push: directory => dispatch(push(directory)),
  fetchStationAreas: (orgId) => dispatch(fetchStationAreas(orgId)),
  storeSelectedStation: station => dispatch(storeSelectedStation(station)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StationView));
