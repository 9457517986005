import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    padding: "20px",
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none"
  },
  text: {
    color: theme.text.primary
  },
  highlightedText: {
    color: theme.palette.secondary.main
  }
});

const PricingMethod = ({method, classes}) => {
  let methodText = null;
  if (method.billingBaseEnergy){
    methodText = (
    <Typography
      variant="subtitle1"
      className={classes.highlightedText}>
        {method.priceUnitCents / 100 || 0}/kWh
    </Typography>);
  }
  else if (method.billingBaseFixedFee){
    methodText = (
    <Typography
      variant="subtitle1"
      className={classes.highlightedText}>
        {method.priceUnitCents / 100 || 0}€
  </Typography>);
  }
  else if (method.billingBaseTime){
    methodText = (
    <Typography
      variant="subtitle1"
      className={classes.highlightedText}>
        {method.priceUnitCents / 100 || 0}/min
  </Typography>);
  }
  return methodText;
}

const PricingSummary = ({ content, classes }) => (
  <Grid container spacing={0}>
    <Grid item xs={4} style={{ paddingRight: "2px" }}>
      <Paper
        className={classes.paper}
        square
        style={{ borderRadius: "6px 0 0 6px", padding: "8px" }}
      >
        <div>
          <Typography variant="subtitle2" className={classes.text} noWrap>
            Total
          </Typography>
          <Typography variant="h5" className={classes.highlightedText}>
            {content.priceTotalCents / 100 || 0}
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                className={classes.highlightedText}
              >
                €
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Grid>
    <Grid item xs={4} style={{ paddingRight: "2px" }}>
      <Paper className={classes.paper} square style={{ padding: "8px" }}>
        <div>
          <Typography variant="subtitle2" className={classes.text} noWrap>
            Preauthorized
          </Typography>
          <Typography variant="h5" className={classes.text}>
            {content.preauthorizedAmountCents / 100 || 0}
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            €
          </Typography>
        </div>
      </Paper>
    </Grid>
    <Grid item xs={4} style={{ paddingRight: "2px" }}>
      <Paper
        className={classes.paper}
        square
        style={{ borderRadius: "0 6px 6px 0", padding: "8px" }}
      >
        <div>
          <Typography variant="subtitle2" className={classes.text} noWrap>
            Refunded
          </Typography>
          <Typography variant="h5" className={classes.text}>
            {content.amountRefundedCents / 100 || 0}
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            €
          </Typography>
        </div>
      </Paper>
    </Grid>
  </Grid>
);

export default withStyles(styles)(PricingSummary);
