import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  fetchStationAreas,
  selectArea,
  storeSelectedStation,
} from "../../actions/stations";
import StationAreaList from "../stations/StationAreaList";
import { storageKey } from "../../localStorage";

class StationAreasView extends Component {
  componentDidMount() {
    if (
      localStorage.getItem(storageKey.PAYMENT) &&
      localStorage.getItem(storageKey.CHARGING_IN_PROGRESS) === "true"
    ) {
      this.props.push("/charging");
    }

    const { themeIsLoading, theme } = this.props;
    if (!themeIsLoading && !!theme.vendorId) {
      this.props.fetchStationAreas(theme.vendorId);
    }
  }

  handleClick = (areaId) => {
    const result = this.props.stationAreas.find(
      ({ stationAreaId }) => stationAreaId === areaId
    );
    this.props.selectArea(result);
  };

  render() {
    const { isLoading, stationAreas, storeSelectedStation, push } = this.props;
    return (
      <StationAreaList
        stationAreas={stationAreas}
        redirect={(id) => push("/station/" + id)}
        storeSelectedStation={(station) => {
          this.handleClick(station.stationAreaId);
          storeSelectedStation(station);
        }}
        isLoading={isLoading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme,
  stationAreas: state.data.stationAreas,
  isLoading: state.data.isLoading,
  activeTab: state.navigation.activeTabIndex,
  selectedArea: state.selections.selectedArea,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStationAreas: (orgId) => dispatch(fetchStationAreas(orgId)),
  selectArea: (stationArea) => dispatch(selectArea(stationArea)),
  storeSelectedStation: (station) => dispatch(storeSelectedStation(station)),
  push: (directory) => dispatch(push(directory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StationAreasView);
