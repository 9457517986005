export const chargingActions = {
  CHARGING_IN_PROGRESS: "CHARGING_IN_PROGRESS",
  CLEAR_CHARGING_STATUS: "CLEAR_CHARGING_STATUS",
  GET_STATUS: "GET_STATUS",
  GET_STATUS_SUCCESS: "GET_STATUS_SUCCESS",
  GET_STATUS_FAILURE: "GET_STATUS_FAILURE",
  INITIALIZE_CHARGE: "INITIALIZE_CHARGE",
  RESET_CHARGING: "RESET_CHARGING",
  STOP_CHARGING: "STOP_CHARGING",
  STOP_CHARGING_SUCCESS: "STOP_CHARGING_SUCCESS",
  WAITING: "WAITING",
  STATUS_QUERY_IN_PROGRESS: "STATUS_QUERY_IN_PROGRESS"
};

export const chargingStatusText = {
  PREPARING: "Connect The Charger To Your Car", //"Preparing Charging",
  CHARGING: "Charging",
  ENDING: "Ending Charging"
};

export const chargingAction = {
  clearChargingStatus: () => ({ type: chargingActions.CLEAR_CHARGING_STATUS }),
  getStatus: (payment) => ({ type: chargingActions.GET_STATUS, payment }),
  getStatusSuccess: (status) => ({ type: chargingActions.GET_STATUS_SUCCESS, status }),
  initializeCharge: (payment) => ({ type: chargingActions.INITIALIZE_CHARGE, payment }),
  setChargingInProgress: (inProgress) => ({ type: chargingActions.CHARGING_IN_PROGRESS, inProgress}),
  stopCharging: (payment) => ({ type: chargingActions.STOP_CHARGING, payment }),
  stopChargingSuccess: (status) => ({ type: chargingActions.STOP_CHARGING_SUCCESS, status }),
  resetCharging: () => ({ type: chargingActions.RESET_CHARGING }),
  waiting: () => ({ type: chargingActions.WAITING }),
  setStatusQueryInProgress: (inProgress) => ({ type: chargingActions.STATUS_QUERY_IN_PROGRESS, inProgress}),
};
