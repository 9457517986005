import React from "react";
import Grid from "@material-ui/core/Grid";
import ChargingUnitButton from "./ChargingUnitButton";

const connectorConnected = (connector) => {
  if (connector.chargingUnitId.includes('Elinta')){
    if (connector.elintaConnectorStatus >= 3 && connector.elintaConnectorStatus <= 5 ) return true;
    else if (connector.elintaConnectorStatus == 7) return true;
    else return false;
  }
  else return true;
}

const ChargingUnits = ({ units, selectCharger, push }) => (
  <Grid container>
    { units[0] !== undefined ? (
    <React.Fragment>
    <Grid
      item
      xs={5}
      onClick={() => {
        if (units[0].available && connectorConnected(units[0])) {
          selectCharger(units[0]);
          push("/amount");
        }
      }}
    >
      <ChargingUnitButton
        content={{
          available: units[0].available,
          location: "",
          type: units[0].standard,
          chargerId: units[0].chargingUnitId,
          status: units[0].elintaConnectorStatus
        }}
      />
    </Grid>
    </React.Fragment>
    ) : ( <Grid item xs={5} /> )}
    <Grid item xs={2} />
    { units[1] !== undefined ? (
    <React.Fragment>
    <Grid
      item
      xs={5}
      onClick={() => {
        if (units[1].available && connectorConnected(units[1])) {
          selectCharger(units[1]);
          push("/amount");
        }
      }}
    >
      <ChargingUnitButton
        content={{
          available: units[1].available,
          location: "",
          type: units[1].standard,
          chargerId: units[1].chargingUnitId,
          status: units[1].elintaConnectorStatus
        }}
      />
    </Grid>
    </React.Fragment>
    ) : ( <Grid item xs={5} /> )}
  </Grid>
);

export default ChargingUnits;
