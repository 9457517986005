import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import { storageKey } from "../../localStorage";

const styles = {
  root: {
    flexGrow: 1,
    fontFamily: 'Montserrat'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  header: {
    color: "white"
  },
  logo: {
    height: "28px"
  },
  ucLogo: {
    height: "58px"
  },
  logoArea: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  }
};

const PreviousChargeButton = ({ charging, label, navigateTo }) => {

  const previousChargingFound = Object.keys(charging.status).length > 0;
  let path = "/charging";

  if (previousChargingFound) {
    path = "/summary";
  }

  return (
    <Button color="inherit" onClick={() => navigateTo(path)}>
      {label}
    </Button>
  );
};

const MobileNavigationBar = props => {
  const { classes, charging, navigateTo, logoUrl } = props;

  const payment = localStorage.getItem(storageKey.PAYMENT);
  const chargingInProgress = JSON.parse(localStorage.getItem(storageKey.CHARGING_IN_PROGRESS));

  // Never show top bar in charging view
  let hidden = props.hidden;
  if (props.location === "/charging") {
    hidden = true;
  }

  return (
    !hidden && (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {props.location === "/" ? (
              <BarWithMenu {...props} />
            ) : (
              <BarWithBackButton {...props} />
            )}
            <Typography variant="h6">{props.title}</Typography>
            {payment && chargingInProgress === "true" && (
              <PreviousChargeButton
                charging={charging}
                label="Return to previous charge"
                navigateTo={path => navigateTo(path)}
              />
            )}
            <div className={classes.logoArea}>
              <a href="/#"><img className={ logoUrl.includes('unifiedchargers.png') ? classes.ucLogo : classes.logo} src={logoUrl} alt="logo"/></a>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  );
};

const BarWithMenu = ({
  classes,
  isOpen,
  onClose,
  links,
  toggleNavigation,
  navigateTo,
  charging,
  hasBackToProvider,
  vendorUrl
}) => (
  <Fragment>
    <IconButton
      color="inherit"
      aria-label="Menu"
      onClick={() => {
        toggleNavigation(isOpen);
      }}
    >
      <MenuIcon />
    </IconButton>
    <Drawer open={isOpen} onClose={toggleNavigation}>
      <div
        tabIndex={0}
        role="button"
        onClick={toggleNavigation}
        onKeyDown={toggleNavigation}
      >
        <List>
          {links.map(link => (
            <ListItem
              key={link.label}
              button
              onClick={event => {
                event.persist();
                navigateTo(link.path);
              }}>
              <ListItemText primary={link.label} />
            </ListItem>
          ))}
          {vendorUrl &&
            <ListItem key={"link"} component="a" href={vendorUrl}>
              <ListItemText primary={"Back"} />
            </ListItem>
          }
        </List>
      </div>
    </Drawer>
  </Fragment>
);

const BarWithBackButton = ({ classes, navigateTo, location }) => {
  let prevLocation = "/";

  if (location === "/station") prevLocation = "/";
  if (location === "/amount") prevLocation = "/station";
  if (location === "/payment") prevLocation = "/amount";

  return (
    <IconButton
      color="inherit"
      aria-label=""
      onClick={() => navigateTo(prevLocation)}
    >
      <KeyboardBackspace />
    </IconButton>
  );
};

export default withStyles(styles)(MobileNavigationBar);
