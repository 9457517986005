import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

export default class FormDialog extends React.Component {
  state = {};

  handleClose = selection => {
    this.props.toggleDialog(false);

    if (this.state.amount && selection === "save") {
      this.props.passValue(parseInt(this.state.amount));
    }
  };

  handleChange = value => event => {
    this.setState({
      [value]: event.target.value
    });
  };

  render() {
    const { showDialog } = this.props;
    const { amount } = this.state;
    const { minimumPreAuthValue } = this.props;
    const minAmount = minimumPreAuthValue < 10 ? minimumPreAuthValue : 10;

    return (
      <div>
        <Dialog
          open={showDialog}
          onClose={() => this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Amount</DialogTitle>
          <Typography id="form-dialog-min-amount" align="center">Minimum amount is { minAmount } €.</Typography>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="amount"
              type="number"
              onChange={this.handleChange("amount")}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose("cancel")} color="primary">
              Cancel
            </Button>

            <Button
              onClick={() => this.handleClose("save")}
              color="primary"
              disabled={!amount || amount < minAmount}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
