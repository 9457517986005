import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import MobileNavigationBar from "../navigation/MobileNavigationBar";
import { toggleNavigationMenu } from "../../actions/navigation";

const TopNavigationBar = ({
  navigationMenuIsOpen,
  title,
  links,
  toggleNavigationMenu,
  push,
  location,
  charging,
  vendorUrl,
  logoUrl
}) => {
  let hidden = false;
  if (location === "/charging" || location === "/summary") hidden = true;

  return (
    <MobileNavigationBar
      isOpen={navigationMenuIsOpen}
      links={links}
      toggleNavigation={isOpen => toggleNavigationMenu(isOpen)}
      navigateTo={path => push(path)}
      location={location}
      charging={charging}
      hidden={hidden}
      vendorUrl={vendorUrl}
      logoUrl={logoUrl}
    />
  );
};

const mapStateToProps = state => ({
  navigationMenuIsOpen: state.navigation.isOpen,
  title: state.navigation.title,
  location: state.router.location.pathname,
  links: state.navigation.menuLinks,
  charging: state.charging
});

const mapDispatchToProps = dispatch => ({
  toggleNavigationMenu: isOpen => dispatch(toggleNavigationMenu(isOpen)),
  push: directory => dispatch(push(directory))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNavigationBar);
