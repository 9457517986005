import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import LocationOnRounded from "@material-ui/icons/LocationOnRounded"

const styles = theme => ({
  headerText: {
    color: theme.text.primary,
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: 'Montserrat'
  },
  innertext: {
    color: theme.text.primary,
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: 'Montserrat'
  },
  smallIcons:{
    color: theme.text.accent,
    width: "15px",
    height: "17px",
    paddingTop: "4px",
    marginRight: "5px"
  },
  topPadding: {
    paddingTop: "13px"
  }
});

const StationAddress = ({ classes, name, address }) => (
  <Grid container spacing={0} className={classes.topPadding} >
    <Grid item xs={12} align="left">
      <Typography className={classes.headerText}>
        {name}
      </Typography>
    </Grid>
    <Grid container item xs={12} align="left">
      <Grid item>
            <LocationOnRounded className={classes.smallIcons} />
      </Grid>
      <Grid item>
        <Typography className={classes.innertext}>
          {address}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

export default withStyles(styles)(StationAddress);
