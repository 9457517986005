import React from "react";
import Typography from "@material-ui/core/Typography";


const PrivacyPolicyContentFI = () => {

  return (
    <>
    <Typography variant="body2">Unified Chargers Oy:n sähköautojen latauspalvelun asiakkaiden henkilötietojen käsittely</Typography>

    <Typography variant="subtitle1">KUKA KÄSITTELEE TIETOJASI?</Typography>
    <Typography variant="body2">Unified Chargers Oy (y-tunnus: 2851232-3)</Typography>
    <Typography variant="body2">puh. +358 40 765 5038</Typography>

    <Typography variant="subtitle1">KENEEN VOIT OLLA YHTEYDESSÄ TIETOSUOJAAN LIITTYVISSÄ KYSYMYKSISSÄ?</Typography>
    <Typography variant="body2">Mikäli sinulla on tietosuojaan liittyviä kysymyksiä tai haluat käyttää oikeuksiasi, voit olla yhteydessä:</Typography>
    <Typography variant="body2">Jukka Anttonen, toimitusjohtaja</Typography>
    <Typography variant="body2">jukka.anttonen@unifiedchargers.fi</Typography>

    <Typography variant="subtitle1">MITÄ HENKILÖTIETOJA KÄSITTELEMME?</Typography>
    <Typography variant="body2">Käsittelemme sinusta seuraavia asiakassuhteen hoitamista varten tarvittavia tietoja:</Typography>
    <ul>
      <li><Typography variant="body2">Nimi</Typography></li>
      <li><Typography variant="body2">Käyttäjätunnukset ja salasanat</Typography></li>
      <li><Typography variant="body2">Sähköpostiosoite</Typography></li>
      <li><Typography variant="body2">Maksutiedot, kuten luottokortin numero, oston loppusumma sekä muut tiedot maksutapahtumasta</Typography></li>
      <li><Typography variant="body2">IP-osoite </Typography></li>
    </ul>
    <Typography variant="body2">Mikäli vierailet verkkosivustollamme, käsittelemme evästeiden avulla kerättävää tietoa siitä, mistä vierailet verkkosivustollamme sekä millä sivustomme osilla vierailet. Tarkempaa tietoa evästeiden käytöstä on alla kohdassa ”Evästeet”.</Typography>

    <Typography variant="subtitle1">MIHIN TARKOITUKSIIN JA MILLÄ PERUSTEELLA KÄSITTELEMME HENKILÖTIETOJASI?</Typography>
    <Typography variant="body2">Henkilötietojen käsittelyn tarkoituksena on asiakassuhteiden hoitaminen, sekä latauspalvelumme ja toimintamme kehittäminen.</Typography>
    <Typography variant="body2">Henkilötietojen käsittelyperusteena on sopimussuhde (asiakassuhde), sopimuksen valmistelu, täytäntöönpano tai oikeutettu etumme sopimussuhteen perusteella.</Typography>

    <Typography variant="subtitle1">MISTÄ SAAMME HENKILÖTIETOSI?</Typography>
    <Typography variant="body2">Keräämme henkilötietojasi ensisijaisesti sinulta itseltäsi.</Typography>

    <Typography variant="subtitle1">KENELLE LUOVUTAMME HENKILÖTIETOJASI?</Typography>
    <Typography variant="body2">Emme luovuta henkilötietoja kolmansille osapuolille ilman suostumustasi. Voimme kuitenkin luovuttaa henkilötietoja viranomaisille lainsäädännön niin edellyttäessä.</Typography>
    <Typography variant="body2">Olemme ulkoistaneet henkilötietojen käsittelyä maksunvälityspalvelun toimittajallemme, Stripe Payments Europe Ltd:lle.</Typography>

    <Typography variant="subtitle1">SIIRRÄMMEKÖ HENKILÖTIETOJASI KOLMANTEEN MAAHAN?</Typography>
    <Typography variant="body2">Maksunvälittäjämme palvelimia sijaitsee Yhdysvalloissa. Mahdolliset tietojen siirrot Yhdysvaltoihin perustuvat Euroopan komission mallisopimuslausekkeisiin (vakiolausekkeet). Emme muutoin siirrä henkilötietoja EU:n tai ETA:n ulkopuolelle.</Typography>

    <Typography variant="subtitle1">KUINKA KAUAN SÄILYTÄMME HENKILÖTIETOJASI?</Typography>
    <Typography variant="body2">Säilytämme henkilötietoja niin kauan kuin se on tarpeen henkilötietojen käsittelyn tarkoitusten kannalta tai lakisääteisten velvollisuuksiemme noudattamiseksi. Arvioimme tietojen säilyttämisen tarpeellisuutta säännöllisesti ja poistamme tiedot, kun ne eivät ole enää tarpeellisia siihen tarkoitukseen, jota varten olemme ne keränneet.</Typography>

    <Typography variant="subtitle1">EVÄSTEET</Typography>
    <Typography variant="body2">Evästeet (cookies) ovat pieniä tiedostoja, jotka tallennetaan käyttäjän tietokoneelle tai muulle laitteelle sivustoa selattaessa. Evästeet mahdollistavat esimerkiksi kävijämäärien mittauksen. Halutessasi voit rajoittaa evästeiden käyttöä tai estää evästeiden käytön kokonaan laitteesi selainasetuksista. Huomioithan, että evästeiden estäminen saattaa vaikuttaa verkkosivujen toiminnallisuuteen.</Typography>
    <Typography variant="body2">Käytämme evästeitä seuraavien tietojen keräämiseen:</Typography>
    <ul>
      <li><Typography variant="body2">Verkkosivustolla vierailun ajankohta, vieraillut sivut ja jokaisella verkkosivulla käytetty aika</Typography></li>
      <li><Typography variant="body2">Sivusto, jonka kautta verkkosivustolle on päädytty</Typography></li>
      <li><Typography variant="body2">Laitetyyppi (matkapuhelin, tabletti, tietokone)</Typography></li>
      <li><Typography variant="body2">Verkkoselaimen tyyppi</Typography></li>
      <li><Typography variant="body2">Käyttöjärjestelmän tyyppi</Typography></li>
      <li><Typography variant="body2">Verkon sijainti ja IP-osoite</Typography></li>
    </ul>
    <Typography variant="body2">Käytämme verkkosivuillamme analytiikkatyökaluja, sekä istuntokohtaisia evästeitä. Istuntokohtainen eväste on tilapäinen ja se poistetaan heti kun palvelun tuottaminen (lataustapahtuma) on päättynyt.</Typography>

    <Typography variant="subtitle1">MITÄ OIKEUKSIA SINULLA ON?</Typography>
    <Typography variant="body2">Oikeuksiesi käyttämiseksi voit olla yhteydessä: tietosuoja@unifiedchargers.fi</Typography>

    <Typography variant="subtitle1">Oikeus saada pääsy tietoihin</Typography>
    <Typography variant="body2">Sinulla on oikeus saada meiltä vahvistus siitä, käsittelemmekö me sinua koskevia henkilötietoja. Sinulla on lisäksi oikeus saada pääsy itseäsi koskeviin henkilötietoihin sekä tietosuoja-asetuksen mukaiset tiedot henkilötietojesi käsittelystä.</Typography>
    <Typography variant="body2">Kun käytät oikeuttasi saada pääsy tietoihin, toimitamme sinulle jäljennöksen käsittelemistämme henkilötiedoistasi. Jos pyydät useampia jäljennöksiä, voimme periä niistä hallinnollisiin kustannuksiin perustuvan kohtuullisen maksun.</Typography>

    <Typography variant="subtitle1">Oikeus tietojen oikaisemiseen</Typography>
    <Typography variant="body2">Sinulla on oikeus pyytää meitä oikaisemaan epätarkat ja virheelliset tiedot ilman aiheetonta viivytystä. Sinulla on lisäksi oikeus saada puutteelliset henkilötiedot täydennettyä toimittamalla meille lisäselvitystä.</Typography>

    <Typography variant="subtitle1">Oikeus tietojen poistamiseen</Typography>
    <Typography variant="body2">Sinulla on oikeus saada meidät poistamaan sinua koskevat henkilötiedot ilman aiheetonta viivytystä, jos:</Typography>
    <ul>
      <li><Typography variant="body2">henkilötietojasi ei enää tarvita niihin tarkoituksiin, joita varten ne kerättiin tai joita varten niitä muutoin käsiteltiin;</Typography></li>
      <li><Typography variant="body2">peruutat suostumuksen, johon käsittely on perustunut eikä kyseisten tietojen käsittelyyn ole muuta laillista perustetta;</Typography></li>
      <li><Typography variant="body2">vastustat henkilötietojesi käsittelyä henkilökohtaiseen erityiseen tilanteeseesi liittyvällä perusteella eikä käsittelyyn ole olemassa perusteltua syytä;</Typography></li>
      <li><Typography variant="body2">olemme käsitelleet henkilötietoja lainvastaisesti; tai</Typography></li>
      <li><Typography variant="body2">henkilötiedot on poistettava meihin sovellettavan lakisääteisen velvoitteen noudattamiseksi.</Typography></li>
    </ul>

    <Typography variant="subtitle1">Oikeus käsittelyn rajoittamiseen</Typography>
    <Typography variant="body2">Sinulla on oikeus siihen, että rajoitamme henkilötietojesi käsittelyä siten, että henkilötietojasi saa säilyttämisen lisäksi käsitellä ainoastaan suostumuksellasi tai oikeudellisen vaateen esittämiseksi, laatimiseksi tai puolustamiseksi taikka toisen henkilön oikeuksien suojaamiseksi, jos:</Typography>
    <ul>
      <li><Typography variant="body2">kiistät henkilötietojesi paikkansapitävyyden, jolloin rajoitamme käsittelyä tietojen paikkansapitävyyden varmistamisen ajaksi;</Typography></li>
      <li><Typography variant="body2">käsittelemme henkilötietojasi lainvastaisesti, ja vastustat henkilötietojen poistamista ja vaadit sen sijaan henkilötietojesi käytön rajoittamista;</Typography></li>
      <li><Typography variant="body2">emme tarvitse enää henkilötietojasi käsittelyn tarkoituksiin, mutta sinä tarvitset niitä oikeudellisen vaateen esittämiseksi, laatimiseksi tai puolustamiseksi; tai </Typography></li>
      <li><Typography variant="body2">olet vastustanut henkilötietojesi käsittelyä henkilökohtaiseen erityiseen tilanteeseesi liittyvällä perusteella ja odotat sen toteamista, syrjäyttävätkö meidän oikeutetut perusteemme vastustamisesi perusteet.</Typography></li>
    </ul>

    <Typography variant="subtitle1">Oikeus siirtää tiedot järjestelmästä toiseen</Typography>
    <Typography variant="body2">Sinulla on oikeus saada meille toimittamasi henkilötiedot jäsennellyssä, yleisesti käytetyssä ja koneellisesti luettavassa muodossa sekä oikeus siirtää kyseiset tiedot toiselle rekisterinpitäjälle, jos:</Typography>
    <ul>
      <li><Typography variant="body2">suoritamme käsittelyä automaattisesti; ja</Typography></li>
      <li><Typography variant="body2">käsittely perustuu joko suostumukseesi tai se on tarpeen meidän välisemme sopimuksen täytäntöön panemiseksi taikka sopimuksen tekemistä edeltävien toimenpiteiden toteuttamiseksi pyynnöstäsi.</Typography></li>
    </ul>
    <Typography variant="body2">Oikeus siirtää tiedot järjestelmästä toiseen rajoittuu menettelyyn, joka ei vaikuta haitallisesti muiden oikeuksiin tai vapauksiin.</Typography>

    <Typography variant="subtitle1">Oikeus vastustaa henkilötietojen käsittelyä</Typography>
    <Typography variant="body2">Sinulla on oikeus vastustaa henkilötietojesi käsittelyä henkilökohtaiseen erityiseen tilanteeseesi liittyvällä perusteella, jos käsittelylle ei ole olemassa perusteltua syytä.</Typography>

    <Typography variant="subtitle1">OIKEUS TEHDÄ VALITUS VALVONTAVIRANOMAISELLE</Typography>
    <Typography variant="body2">Sinulla on oikeus tehdä valitus tietosuojavaltuutetulle, jos katsot, että henkilötietojen käsittelyssä on loukattu tietosuoja-asetukseen perustuvia oikeuksiasi. Suomessa valvova viranomainen on tietosuojavaltuutettu (www.tietosuoja.fi)</Typography>

    <Typography variant="subtitle1">KUINKA VARMISTAMME TIETOTURVAN HENKILÖTIETOJEN KÄSITTELYSSÄ?</Typography>
    <Typography variant="body2">Säilytämme henkilötietosi järjestelmissä, jotka ovat palomuurein, salasanoin ja muilla toimialalla kulloinkin yleisesti hyväksyttävin teknisin ja organisatorisin keinoin suojattuja.</Typography>
    <Typography variant="body2">Ainoastaan niillä henkilöillä, joilla on tarve käsitellä henkilötietoja työtehtäviensä hoitamiseksi, on pääsy käsittelemiimme henkilötietoihin.</Typography>

    </>
  );
};

export default PrivacyPolicyContentFI;
