import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none"
  },
  text: {
    color: theme.text.primary
  }
});

const ChargingSummary = ({ content, classes }) => {
  const { elapsedTime } = content;
  const { energyDelivered, chargedPercent } = content.chargingStatus;

  const energyFormat = energyDelivered && !isNaN(energyDelivered) ? energyDelivered / 1000 : 0.00;
  const parsedEnergyDelivered = parseFloat(energyFormat.toFixed(2));

  return (
    <Grid container spacing={0}>
      <Grid item xs={4} style={{ paddingRight: "2px" }}>
        <Paper
          className={classes.paper}
          square
          style={{ borderRadius: "6px 0 0 6px", padding: "8px" }}
        >
          <div>
            <Typography variant="subtitle2" className={classes.text} noWrap>
              Time elapsed
            </Typography>
            <Typography variant="h5" className={classes.text}>
              {elapsedTime || 0}
            </Typography>
            <Typography variant="subtitle1" className={classes.text}>
              min
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4} style={{ paddingRight: "2px" }}>
        <Paper className={classes.paper} square style={{ padding: "8px" }}>
          <div>
            <Typography variant="subtitle2" className={classes.text} noWrap>
              Energy
            </Typography>
            <Typography variant="h5" className={classes.text}>
              {parsedEnergyDelivered || 0}
            </Typography>
            <Typography variant="subtitle1" className={classes.text}>
              kWh
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4} style={{ paddingRight: "2px" }}>
        <Paper
          className={classes.paper}
          square
          style={{ borderRadius: "0 6px 6px 0", padding: "8px" }}
        >
          <div>
            <Typography variant="subtitle2" className={classes.text} noWrap>
              Percentage
            </Typography>
            <Typography variant="h5" className={classes.text}>
              {chargedPercent || 0}
            </Typography>
            <Typography variant="subtitle1" className={classes.text}>
              %
            </Typography>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ChargingSummary);
