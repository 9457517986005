import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  text: {
    color: theme.text.primary,
    padding: "10px"
  },
});

const PageHeader = ({ classes, title }) => (
  <Typography
    className={classes.text}
    variant="h6"
    align="center"
  >
    {title}
  </Typography>
);

export default withStyles(styles)(PageHeader)
