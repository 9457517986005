import React from "react";
import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";


const styles = theme => ({
    form: {
      alignSelf: "center",
      borderRadius: "7px",
      fontFamily: 'Montserrat'
    },
    error: {
      lineHeight: "22px",
      fontSize: "16px",
      color: theme.text.primary,
      fontFamily: 'Montserrat'
    },
    hidden: {
      display: "none"
    },
    cardElement: {
      borderRadius: "4px",
      padding: "12px",
      marginBottom: "6px",
      border: "1px solid rgba(50, 50, 93, 0.1)",
      maxHeight: "40px",
      background: "white",
      boxSizing: "border-box",
      fontFamily: 'Montserrat',
      "&:input": {
        borderRadius: "6px",
        marginBottom: "6px",
        padding: "12px",
        border: "1px solid rgba(50, 50, 93, 0.1)",
        maxHeight: "40px",
        fontSize: "16px",
        width: "100%",
        backgroundColor: "white",
        boxSizing: "border-box",
      },
    },
    /* Buttons and links */
    button: {
      background: theme.palette.secondary.main,
      fontFamily: "Arial, sans-serif",
      color: theme.text.white,
      borderRadius: "4px",
      border: "0",
      marginBottom: "8px",
      padding: "12px 16px",
      fontSize: "16px",
      fontWeight: "600",
      cursor: "pointer",
      display: "block",
      transition: "all 0.2s ease",
      width: "100%",
      fontFamily: 'Montserrat',
      "&:hover": {
        filter: "contrast(115%)"
      },
      "&:disabled": {
        opacity: 0.5,
        cursor: "default"
      }
    },
    emailInput: {
      backgroundColor: "#fff",
      borderRadius: "4px",
      marginBottom: "6px",
      fontFamily: 'Montserrat'
    }
});

const CheckoutForm = ({ requestCompletePreAuthorize, clientSecretValue, classes }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [cardError, setCardError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isAllValid, setAllValid] = useState(false);
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const stripe = useStripe();
  const elements = useElements();

    const handleSubmit = async (event) => {
      event.preventDefault();
      setProcessing(true);

      // 1. Check if form is valid
      if (!checkFormValidity()) {
        setProcessing(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);

      // 2. Create payment method
      //await stripe.createPaymentMethod({ type: "card", card: cardElement });
      // this is not needed, because stripe.confirmCardPayment does also

      // 3. Confirm the payment ( Note: this method creates payment method automatically)
      stripe.confirmCardPayment(clientSecretValue, {
        payment_method: {
            card: cardElement,
            billing_details: {
                name: "Mobile app",
                email: email
            },
        }
      // 4. Complete the pre-authorize
      }).then(result => {
        if (result.error) {
          setCardError("Could not complete the pre-authorization with given card details");
          setAllValid(false);
          setProcessing(false);
        } else {
          requestCompletePreAuthorize(email);
        }
      }).catch(error => {
      });


    };

    const checkFormValidity = () => {
      let isEmailValid = false;
      if (email == "") setEmailError("Email address is not valid");
      else isEmailValid = (email.match(emailRegex)) ? true : false;
      let isCardValid = (cardError === "" && cardComplete);

      // This needs to be done, because Stripe's own input cannot handle this kind of validation for some reason.
      if ((!cardError || cardError === "") && !cardComplete) { setCardError("Card info is not complete"); }

      let isValid = isEmailValid && isCardValid;
        setAllValid(isValid);
        return isValid;
    };

    const handleEmailChange = (event) => {
      let newValue = event.target.value;
      newValue = newValue.replace(/\s+/g, '');
      setEmail(newValue);
      setEmailError(newValue.match(emailRegex) ? "" : "Email address is not valid");
    };

    const handleCardChange = async (event) => {
      let possibleError = event.error;
      setCardError(possibleError ? possibleError.message : "");
      setCardComplete(event.complete);
    };

    const cardStyles = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: "Arial, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        }
      },
      hidePostalCode: true,
    };

    return (
      <form className={classes.form} id="payment-form" onSubmit={handleSubmit}>
        <TextField className={classes.emailInput} value={email} onChange={handleEmailChange}
          id="outlined-basic" placeholder="Email for receipt" variant="outlined" margin="dense" InputLabelProps={{shrink: false}} fullWidth
        />
        <CardElement className={classes.cardElement} id="card-element" options={cardStyles} onChange={handleCardChange} />
        <button type="submit" className={classes.button} disabled={!stripe || processing} id="submit">
          Pay
        </button>
        {/* Show any error that happens when processing the payment */}
        { (!isAllValid && emailError) &&
          <div className={classes.error} role="alert">
            {emailError}
          </div>
        }
        { (!isAllValid && cardError) &&
          <div className={classes.error} role="alert">
            {cardError}
          </div>
        }
      </form>
    );
};

export default withStyles(styles)(CheckoutForm);
