import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import StationNumber from "./StationNumber";
import StationAddress from "./StationAddress";

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    color: "#FFF"
  },
  numberText: {
    color: theme.palette.icons.main,
    fontSize: "40px",
    fontWeight: "700"
  },
  labelText: {
    color: theme.text.primary,
    fontSize: "24px",
    fontWeight: "700",
    paddingTop: "12px"
  },
  noWrap:{
    flexWrap: "nowrap"
  }
});

const StationInfo = ({ stationNumber, name, address, classes }) => (
    <Grid container spacing={2} direction="row" justify="center" className={classes.noWrap}>
      <Grid item>
          <StationNumber number={stationNumber} />
      </Grid>
      <Grid item>
        <StationAddress name={name} address={address} />
      </Grid>
    </Grid>
);
export default withStyles(styles)(StationInfo);