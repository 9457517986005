import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    color: "#FFF"
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    padding: "10px 8px 10px 8px"
  },
  text: {
    color: theme.text.primary,
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: 'Montserrat'
  },
  bottomPadding: {
    paddingBottom: "10px"
  },
  bottomPadding3: {
    paddingBottom: "3px"
  }
});

const TimeBased = ({ station, classes }) => {
  let payment = null;
  if (station == undefined) return payment;
  if (station.billingBaseFixedFee == true){
    payment = (
      <Grid className={classes.bottomPadding3}>
        <Paper
          className={classes.paper}
          square={false}
          key={station.stationId}
        >
          <Grid container spacing={0}>
              <Grid item xs={6} align="left">
                <Typography className={classes.text}>
                  Starting fee
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography className={classes.text}>
                  {station.fixedFeePrice / 100} €
                </Typography>
              </Grid>
            </Grid>
        </Paper>
      </Grid>
    );
  }
  return payment;
};

const EnergyBased = ({ station, classes }) => {
  let payment = null;
  if (station == undefined) return payment;
  if (station.billingBaseEnergy == true){
    payment = (
      <Grid className={classes.bottomPadding3}>
        <Paper
          className={classes.paper}
          square={false}
          key={station.stationId}
        >
          <Grid container spacing={0}>
              <Grid item xs={6} align="left">
                <Typography className={classes.text}>
                  Per kWh
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography className={classes.text}>
                  {station.energyPrice / 100} € / kWh
                </Typography>
              </Grid>
            </Grid>
        </Paper>
      </Grid>
    );
  }
  return payment;
};

const StartingFee = ({ station, classes }) => {
  let payment = null;
  if (station == undefined) return payment;
  if (station.billingBaseTime == true){
    payment = (
      <Grid className={classes.bottomPadding3}>
        <Paper
          className={classes.paper}
          square={false}
          key={station.stationId}
        >
          <Grid container spacing={0}>
              <Grid item xs={6} align="left">
                <Typography className={classes.text}>
                  Per minute
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography className={classes.text}>
                  {station.timePrice / 100} € / min
                </Typography>
              </Grid>
            </Grid>
        </Paper>
      </Grid>
    );
  }
  return payment;
};

const Prices = ({ station, classes }) => (
    <Grid container spacing={0} className={classes.bottomPadding}>
      <Grid item xs={false} sm={2} md={4} />
      <Grid item xs={12} sm={8} md={4} >
        <TimeBased classes={classes} station={station} />
        <EnergyBased classes={classes} station={station} />
        <StartingFee classes={classes} station={station} />
      </Grid>
    </Grid>
);

export default withStyles(styles)(Prices);
