export const SELECT_CHARGER = "SELECT_CHARGER";

export const selectedCharger = chargerUnit => dispatch => {
  dispatch({ type: SELECT_CHARGER, chargerUnit });
};

export function selectCharger(chargerUnit) {
  return dispatch => {
    if (chargerUnit) {
      dispatch(selectedCharger(chargerUnit));
    } else {
      dispatch(selectCharger());
    }
  };
}
