import React, { classes, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { storageKey } from "../../localStorage";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const styles = theme => ({
    text: {
      color: theme.text.primary,
      padding: "10px"
    },
  });

const TimeoutCalculator = ({ classes, title }) => {
    const [seconds, setSeconds] = useState(60);
    const timeoutTime = 60; // one minute
    //const { classes } = props;
    const checkTitle = (title) => {
        if (title == 'Connect The Charger To Your Car'){
            return true;
        }
        return false;
    }

    useEffect(() => {
      const interval= setInterval(() => {
        const storedTime = localStorage.getItem(storageKey.INITIALIZE_TIMEOUT_STARTED);
        if (storedTime != ""){
          const startTime = moment(storedTime, "YYYY-MM-DD-HH-mm-ss");
          const currentTime = moment();
          var secondsLeft = timeoutTime - currentTime.diff(startTime, "seconds");
          if (secondsLeft <= 0) {
              stopInterval(interval);
              secondsLeft = "Stopping"
            }
          setSeconds(seconds => secondsLeft);
        }
      }, 1000);
      return () => clearInterval(interval);
    }, []);

    const stopInterval = (id) => {
        clearInterval(id);
    }

    return (
        <Typography
        className={classes.text}
        variant="h6"
        align="center">
        {checkTitle(title) ? (
        seconds ) : ( null )}
      </Typography>
      );
  };

export default withStyles(styles)(TimeoutCalculator);