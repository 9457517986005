import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PageHeaderComponent from "../shared/PageHeaderComponent";
import PrivacyPolicy from "../privacypolicy/PrivacyPolicyContent";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
    fontFamily: 'Montserrat'
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  languageSelect: {
    color: theme.text.accent,
    fontFamily: 'Montserrat',
    filter: "brightness(70%)",
    textDecoration: "none",
    "&:hover": {
      filter: "brightness(100%)"
    },
  },
  active: {
    fontWeight: 600,
    filter: "brightness(100%)"
  },
  divider: {
    color: theme.text.primary
  },
  scrollable: {
    maxHeight: "500px",
    overflowY: "auto",
    overflowX: "hidden",
    maxWidth: "380px",
    minWidth: "260px",
  }
});

const PrivacyPolicyView = ( { classes, scrollable } ) => {
  const [language, setLanguage] = useState("EN");

    return (
        <Grid container className={classes.root} justify="center">
          <Grid item xs={12} md={6} className={scrollable ? classes.scrollable : ""}>
            <Grid container className={classes.header} spacing={2} alignItems="center">
              <Grid item>
                <PageHeaderComponent
                  label={language === "FI" ? "Tietosuojaseloste" : "Privacy Policy" }
                  headerVariant="h5"
                />
              </Grid>
              <Grid item>
                <a className={`${language === "EN" ? classes.active : ""} ${classes.languageSelect}`} onClick={() => setLanguage("EN")}>In English</a>
                <span className={classes.divider}> | </span>
                <a className={`${language === "FI" ? classes.active : ""} ${classes.languageSelect}`} onClick={() => setLanguage("FI")}>Suomeksi</a>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <PrivacyPolicy language={language}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    );
}

export default withStyles(styles)(PrivacyPolicyView);
