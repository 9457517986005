import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormDialog from "../shared/FormDialog";
import { toggleDialog } from "../../actions/dialog";
import { storeAmount } from "../../actions/payment";
import Logo from "../shared/Logo";

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    fontFamily: 'Montserrat'
  },
  paper: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.light,
    boxShadow: "none",
    padding: theme.spacing(2),
    borderRadius: "8px",
    textAlign: "center"
  },
  amountText: {
    color: theme.text.accent,
    fontWeight: 600,
    fontSize: "15px",
    fontFamily: 'Montserrat'
  }

});

const paymentOptions = [10, 15, 20, 30, 50, 80, 100];
const minimumOption = paymentOptions[0];

const PaymentAmount = ({ classes, amount }) => (
  <Paper className={classes.paper} elevation={1}>
    <Typography variant="subtitle1" className={classes.amountText} fontWeight="fontWeightBold" noWrap>
      {`${amount} €`}
    </Typography>
  </Paper>
);

const OtherAmount = ({ classes }) => (
   <Paper
    className={classes.paper}
    elevation={1}
   >
   <Typography 
    variant="subtitle1"
    className={classes.amountText}
    >
       Other amount
     </Typography>
   </Paper>
 );

const AmountsRow = ({ classes, amounts, width, passAmount }) => (
  <Grid container spacing={1}>
    {amounts.map(amount => (
      <Grid
        key={amount}
        item
        xs={width} // 4
        onClick={() => {
          passAmount(amount);
        }}
      >
        <PaymentAmount classes={classes} amount={amount} />
      </Grid>
    ))}
  </Grid>
);

const AmountView = ({
  classes,
  showDialog,
  toggleDialog,
  storeAmount,
  push,
  selectedChargingUnit,
  selectedStation,
}) => {
  if (!selectedChargingUnit) {
    return (
      <div className={classes.root}>
        <Grid container spacing={8}>
          <Grid item xs={12} variant="fullWidth">
            <Paper className={classes.paper}>
              <Grid item xs={12} align="center" onClick={() => push("/")}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  'There seems to have been an error. Press here to return to
                  main page'
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
  const minimumPreAuthorizationAmount = selectedStation.minimumPreAuthorizationAmount == undefined ? minimumOption : selectedStation.minimumPreAuthorizationAmount;
  const filteredPaymentOptions = paymentOptions.filter(option => option >= minimumPreAuthorizationAmount);
  const numberOfOptionShown = Object.keys(filteredPaymentOptions).length;
  return (
    <div className={classes.root}>
      <FormDialog
        showDialog={showDialog}
        toggleDialog={toggleDialog}
        minimumPreAuthValue={minimumPreAuthorizationAmount < minimumOption ? minimumPreAuthorizationAmount : minimumOption}
        passValue={amount => {
          push("/payment");
          storeAmount(amount);
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} align="center">
          <Grid item xs={12} sm={8} md={6} align="center">
            <Logo subHeading="Select sum"/>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ padding: "10px" }}>
        { numberOfOptionShown == 1 ? (
          <React.Fragment>
          <Grid item xs={4} sm={4} md={4} align="center">
            <AmountsRow
              classes={classes}
              amounts={[
                filteredPaymentOptions[0]
              ]}
              width = {12}
              passAmount={amount => {
                push("/payment");
                storeAmount(amount);
              }}
            />
          </Grid>
          </React.Fragment>
          ) : ( null )}
        { numberOfOptionShown == 2 ? (
          <React.Fragment>
          <Grid item xs={8} align="center" style={{ marginRight: "20px" }}>
            <AmountsRow
              classes={classes}
              amounts={[
                filteredPaymentOptions[0],
                filteredPaymentOptions[1],
              ]}
              width = {6}
              passAmount={amount => {
                push("/payment");
                storeAmount(amount);
              }}
            />
          </Grid>
          </React.Fragment>
          ) : ( null )}
          { numberOfOptionShown >= 3 ? (
          <React.Fragment>
          <Grid item xs={12} align="center" style={{ marginRight: "20px" }}>
            <AmountsRow
              classes={classes}
              amounts={[
                filteredPaymentOptions[0],
                filteredPaymentOptions[1],
                filteredPaymentOptions[2]
              ]}
              width = {4}
              passAmount={amount => {
                push("/payment");
                storeAmount(amount);
              }}
            />
          </Grid>
          </React.Fragment>
          ) : ( null )}
          { numberOfOptionShown == 4 ? (
          <React.Fragment>
          <Grid item xs={4} align="center" style={{ marginLeft: "20px" }}>
            <AmountsRow
              classes={classes}
              amounts={[
                filteredPaymentOptions[3],
              ]}
              width = {12}
              passAmount={amount => {
                push("/payment");
                storeAmount(amount);
              }}
            />
          </Grid>
          </React.Fragment>
          ) : ( null )}
          { numberOfOptionShown == 5 ? (
          <React.Fragment>
          <Grid item xs={8} align="center" style={{ marginLeft: "20px" }}>
            <AmountsRow
              classes={classes}
              amounts={[
                filteredPaymentOptions[3],
                filteredPaymentOptions[4]
              ]}
              width = {6}
              passAmount={amount => {
                push("/payment");
                storeAmount(amount);
              }}
            />
          </Grid>
          </React.Fragment>
          ) : ( null )}
          { numberOfOptionShown >= 6 ? (
          <React.Fragment>
          <Grid item xs={12} align="center" style={{ marginLeft: "20px" }}>
            <AmountsRow
              classes={classes}
              amounts={[
                filteredPaymentOptions[3],
                filteredPaymentOptions[4],
                filteredPaymentOptions[5]
              ]}
              width = {4}
              passAmount={amount => {
                push("/payment");
                storeAmount(amount);
              }}
            />
          </Grid>
          </React.Fragment>
          ) : ( null )}
          { numberOfOptionShown == 7 ? (
          <React.Fragment>
          <Grid item xs={12} align="center" style={{ marginRight: "20px" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={4}
                onClick={() => {
                  push("/payment");
                  storeAmount(filteredPaymentOptions[6]);
                }}
              >
                <PaymentAmount classes={classes} amount={filteredPaymentOptions[6]} />
              </Grid>
              {
              <Grid item xs={8} onClick={() => toggleDialog(true)}>
                <OtherAmount classes={classes} />
              </Grid>
              }
            </Grid>
          </Grid>
          </React.Fragment>
          ) : (
          <Grid item xs={7} align="center" >
            <Grid container spacing={1}>
              {
              <Grid item xs={12} onClick={() => toggleDialog(true)}>
                <OtherAmount classes={classes} />
              </Grid>
              }
            </Grid>
          </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ color: "#9fa4b0", fontFamily: 'Montserrat' }}>
            This is a preauthorization only. If the entire sum is not used, the 
            remaining funds are immediately refunded. The minimum charge is 50 cents.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedChargingUnit: state.selections.selectedChargingUnit,
  selectedStation: state.selections.selectedStation,
  showDialog: state.dialog.showDialog
});

const mapDispatchToProps = dispatch => ({
  toggleDialog: value => dispatch(toggleDialog(value)),
  storeAmount: amount => dispatch(storeAmount(amount)),
  push: directory => dispatch(push(directory))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AmountView));
