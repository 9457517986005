import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import navigation from "./navigation";
import data from "./stations";
import dialog from "./dialog";
import payment from "./payment";
import selections from "./selections";
import charging from "./charging";
import { theme, themeHasErrored, themeIsLoading } from "./themeloader";

export default history =>
	combineReducers({
		router: connectRouter(history),
		navigation,
		data,
		dialog,
		payment,
		selections,
    charging,
    theme,
    themeHasErrored,
    themeIsLoading
	});
