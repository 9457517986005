import React from "react";
import StationAreasView from "../containers/StationAreasView";
import StationView from "../containers/StationView";
import AmountView from "../containers/AmountView";
import PaymentView from "../containers/PaymentView";
import ChargingView from "../containers/ChargingView";
import SummaryView from "../containers/SummaryView";
import PrivacyPolicyView from "../containers/PrivacyPolicyView";

import { Switch, Route } from "react-router";

const Routes = (props) => (
  <Switch>
    <Route exact path="/"><StationAreasView {...props}/></Route>
    <Route path="/station/:id"><StationView/></Route>
    <Route path="/amount" component={AmountView} />
    <Route path="/payment" component={PaymentView} />
    <Route path="/charging" component={ChargingView} />
    <Route path="/summary" component={SummaryView} />
    <Route path="/privacy" component={PrivacyPolicyView} />
    <Route component={() => (<StationAreasView {...props} />)} />
  </Switch>
);

export default Routes;
